// site width

.page-container {
  .clearfix();
  max-width: 940px;
  margin: 0 auto;

  @media @content {
    padding: 0 10px;

    #audio-player & {
      padding: 0 40px;
    }
  }
}

#globalfooter {
  z-index: 2000;
  padding-bottom: 140px;

  @media only screen and (max-width: 959px) {
    padding-bottom: 190px;
  }

  @media only screen and (max-width: 540px) {
    padding-bottom: 50px;
  }

  .disclaimer-section {
    padding-bottom: 80px;

    @media only screen and (max-width: 959px) {
      padding-bottom: 70px;
    }

    @media only screen and (max-width: 540px) {
      padding-bottom: 0;
    }
  }
}

.footer {
  position: relative;
  clear: both;

  &:after {
    position: absolute;
    content: "";
    background-color: @grey-dark;
    height: 100%;
    width: 100vw;
    left: calc(~"(100vw - 940px) / 2 * -1");
    z-index: -1;
    top: 0;
    clear: both;

    @media @content {
      left: -10px;
    }

    .lang-no & {
      height: 100px;
    }
  }

  .collaborators {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin: 20px 0;

    @media @mobile {
      width: 100%;
      margin: 0 0 20px;
      text-align: center;
    }

    img {
      max-height: 60px;
      max-width: 300px;
      padding: 0 10px;
    }
  }
}

// ALERT MES GENERAL STYLING
.alert-mes {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: @blue;
  z-index: 9999;
  width: 100%;
  padding: 30px 0px;
  color: @white;
  font-size: @font-size-m;
  -webkit-animation: fadeOut 1000ms linear 5s forwards;
  -moz-animation:    fadeOut 1000ms linear 5s forwards;
  -o-animation:      fadeOut 1000ms linear 5s forwards;
  animation:         fadeOut 1000ms linear 5s forwards;

  @media @mobile {
    font-size: @font-size-s;
  }

  span {
    font-family: @semibold;
  }

  .player-active & {
    bottom: 50px;
  }

  .lang-no & {
    .nrk-fonts(@style:bookitalic);
    color: @nrk-black;
    background: @nrk-yellow;

    span {
      .nrk-fonts(@style:bolditalic);
    }
  }
}

// ERROR MES GENERAL STYLING
.error-mes {
  margin-bottom: 20px;
  background-color: @orange;
  color: @white;
  font-size: @font-size-m;
  position: relative;
  padding: 10px 10px 10px 60px;

  &:before {
    color: @white;
    text-align: center;
    display: block;
    float: left;
    border-radius: 0;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .lang-no & {
    background-color: @nrk-red;
  }
}

.lang-no {
  footer {
    div, a {
      color: @white;
      line-height: 1.5;
    }

    .collaborators {
      position: relative;
      padding: 20px;
      color: @nrk-black;
    }

    .footer-top {
      position: relative;
      min-height: 140px;
      padding: 20px;

      img {
        height: 60px;
        float: right;
      }

      &-links {
        float: right;
        text-align: right;
        padding: 9px 10px;

        a {
          display: block;
        }
      }
  
      &:after {
        position: absolute;
        content: "";
        background-color: @nrk-blue-dark;
        height: 100%;
        width: 100vw;
        left: calc(~"(100vw - 940px) / 2 * -1");
        z-index: -1;
        top: 0;
        clear: both;
    
        @media @content {
          left: -10px;
        }
      }
    }

    .footer-bottom {
      position: relative;
      padding: 20px;

      img {
        display: block;
        height: 26px;
        margin: 0 auto 14px;
      }

      &-copyright {
        text-align: center;
      }

      &-links {
        text-align: center;

        span {
          padding: 0 5px;
        }
      }
  
      &:after {
        position: absolute;
        content: "";
        background-color: @nrk-black-light;
        height: 100%;
        width: 100vw;
        left: calc(~"(100vw - 940px) / 2 * -1");
        z-index: -1;
        top: 0;
        clear: both;
    
        @media @content {
          left: -10px;
        }
      }
    }
  }

  &.player-active {
    footer {
      .footer-bottom {
        padding: 20px 20px 90px;
      }
    }
  }
}
