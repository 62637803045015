h1 {
  font-size: @font-size-xxl;
  font-family: @semibold;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: normal;

  .lang-no & {
    .nrk-fonts(@style:bold);
    text-transform: uppercase;
  }
}

h2 {
  font-size: @font-size-xl;
  font-family: @semibold;
  margin: 0;
  font-weight: normal;

  .lang-no & {
    .nrk-fonts(@style:bold);
  }
}

h3,
.h3 {
  font-family: @semibold;
  font-size: @font-size-xs;
  margin-bottom: 10px;
  margin-top: 0;
  color: @orange;
  text-transform: uppercase;
  font-weight: normal;

  .lang-no & {
    .nrk-fonts(@style:bookitalic);
    color: @nrk-red;
    font-size: @font-size-m;
  }
}
