.page-playlist-container {
  .clearfix();
  border-bottom: solid 1px @grey-light;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;

  .lang-no & {border-bottom: none;}

  @media @mobile {
    .grid(12, 100%, 10px);
    border-bottom: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .cover {
    .column(5);

    img {
      width: 100%;
      object-fit: cover;
    }

    @media @mobile {
      width: 100%;
      float: none;
    }
  }

  .playlist-info {
    .column(7);
    margin-right: 0;

    .title  {
      font-size: @font-size-xxl;
      color: @black;
      font-family: @semibold;
      margin: 0 0 10px 0;
      width: 100%;
      float: left;

      .lang-no & {.nrk-fonts(@style:bolditalic);}
    }

    .actions {
      width: 10%;
      float: left;
      margin-top: 0;
    }

    .text {
      width: 90%;
      float: left;
      font-size: @font-size-l;
      color: @grey;
      margin: 0 0 10px 0;
    }

    @media @mobile {
      padding: 0px;
      padding-top: 20px;
      width: 100%;

      .title  {
        font-size: @font-size-l;
        width: 90%;
      }

      .text  {
        font-size: @font-size-s;
        width: 100%;
      }
    }
  }

  .playlist-btn-container {
    .column(7);
    margin-right: 0;

    @media @mobile {
      width: 100%;
    }

    .playlist-play-btn {
      position: absolute;
      bottom: 20px;
      margin: 0;

      @media @mobile {
        position: static;
        float: left;
        margin: 20px 0 20px;
      }
    }

    &.favorit-playliste-btn-container {
      .playlist-play-btn {
        position: static;
        float: left;
      }
    }
  }
}
