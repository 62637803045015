.artist-container {
  .clearfix();
  position: relative;
  border-bottom: solid 1px @grey-light;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @media @mobile {
    .grid(12, 100%, 10px);
    border-bottom: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .artist-images {
    .column(12);
    margin-bottom: 20px;

    @media @mobile {
      margin-bottom: 0;
    }

    .owl-controls {
      margin-top: 20px;
    }

    .image {
      .column(4);
      margin-right: 0;
      margin-bottom: 11px;

      @media @mobile {
        width: 100%;
        margin: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 100%;
        object-fit: cover;
        display: inline;
      }

      &:first-child {
        .column(8);
        margin-bottom: 0;

        @media @mobile {
          width: 100%;
          margin: 0;
        }
      }
    }

    figure {
      margin: 0px;
      position: relative;

      figcaption {
        .transition(all 200ms ease-in-out);
        opacity: 0;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: @black;
        padding: 5px 10px;
        color: @white;
        font-style: italic;
        font-size: @font-size-s;

        @media @content {
          opacity: 1;
        }

        @media @mobile {
          position: relative;
          background: transparent;
          color: @grey;
          display: block;
          opacity: 1;
          padding: 0;
          margin-top: 5px;
        }
      }

      &:hover {
        figcaption {
          opacity: .9;
        }
      }
    }
  }

  .info {
    .column(8);
    clear: both;

    .name  {
      font-size: @font-size-xxl;
      color: @black;
      font-family: @semibold;
      margin-top: 0;

      .lang-no & {.nrk-fonts(@style:bolditalic);}
    }

    .text {
      font-size: @font-size-l;
      color: @grey;
      margin: 0;
      display: table;
    }

    .locationname {
      margin-bottom: 10px;
    }

    @media @mobile {
      float: none;
      width: 100%;

      .name  {
        font-size: @font-size-xl;
        font-family: @semibold;
        margin-bottom: 10px;
        text-align: center;

        .lang-no & {.nrk-fonts(@style:bolditalic);}
      }

      .text  {
        font-size: @font-size-s;
        width: 100%;
      }

    }

    .artist-thumb-pic {
      .column(2);

      @media @mobile {
        .column-centered(2);
        float: none;
      }

      img {
        width: 100%;
        border-radius: 50%;

        @media @mobile {
          width: 60px;
        }
      }
    }
  }

  .actions {
    float: right;
    margin-right: 2px;
    margin-top: 5px;
    width: 31%;

    @media @mobile {
      width: 100%;
      float: none;
      margin-top: 20px;
    }

    .btn {
      font-size: 3rem;
      border: none;
      width: auto;
      margin-left: 20px;

      &:hover {
        color: inherit;
        text-decoration: none;

        &:before {
          opacity: 0.8;
        }
      }

      @media @mobile {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    &.artist-share {
      width: auto;
      float: right;
      margin-right: 20px;

      @media @mobile {
        margin-bottom: 0;
        margin-top: 0;
      }

      .btn {
        font-size: @font-size-m;

        &:hover {
          &:before {
            color: @blue;
          }
        }

        @media @mobile {
          font-size: @font-size-s;
          margin-right: 0;
        }

        .text {
          margin-top: 0;
        }

        &.btn-share .social-share {
          top: 35px;
        }
      }
    }
  }

  .playlist-btn-container {
    .column(7);
    margin-right: 0;

    @media @mobile {
      width: 100%;
    }

    .playlist-play-btn {
      position: absolute;
      bottom: 20px;
      right: 0;
      margin: 0;

      @media @mobile {
        position: static;
        float: right;
        margin: 20px 0 20px;
      }
    }
  }
}

.artist-contact-form {
  textarea {
    resize: vertical;
  }
}

.lang-no {
  .artist-container {
    border-bottom: none;

    .artist-images {

      .image {
        width: 33.33%;
        margin-bottom: 0;

        @media @mobile {
          width: 100%;
        }

        &:first-child {
          width: 66.66%;
          margin-right: 0;

          @media @mobile {
            width: 100%;
          }
        }

        .full-image-link {
            position: absolute;
            right: 0px;
            bottom: 0px;
            background-color: white;
            padding: 5px 10px;
            opacity: 0.7;
        }
      }
    }

    .info {
      .name  {
        .nrk-fonts(@style:bolditalic);

        @media @mobile {
          .nrk-fonts(@style:bolditalic);
        }
      }
    }
  }
}
