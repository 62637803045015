//
// Based on Semantic.gs
//

// Defaults
@columns: 12;
@gutter-width: 20px;
@total-width: 100%;

// Uncomment these two lines and the star-hack width/margin lines below to enable sub-pixel
// fix for IE6 & 7. See http://tylertate.com/blog/2012/01/05/subpixel-rounding.html
// @min-width: 960;
// @correction: 0.5 / @min-width * 100 * 1%;

body {
  width: 100%;
  .clearfix();
}

// Set grid definition for scope
//
// Calling this overrides any existing grid mixin definitions in a scope. It can be used
// to set the grid settings for a scope, without passing extra parameters with use of .column()
.grid(@columns: @columns, @total-width: @total-width, @gutter-width: @gutter-width) {

  // Row container to subtract outer edge padding
  // Use is completely optional, but this useful to acomodate some designs
  .row(@gutter-width:@gutter-width) {
    margin-left: -@gutter-width / 2;
    margin-right: -@gutter-width / 2;
  }

  // A container at the specified grid width, without padding
  .column-layout(@x, @columns:@columns, @total-width:@total-width, @float:left) {
    @colfix: (@columns/@x);
    @width: ((@total-width/@columns) * @x);
    @guttercol: @gutter-width * (@colfix - 1) / @colfix;
    float: @float;
    width:calc(~'(@{width})' - (@guttercol));
  }

  // A column size container, centered automatically by margin
  .column-centered(@x, @columns:@columns, @total-width:@total-width) {
    width: (@total-width/@columns) * @x;
    margin-left: auto;
    margin-right: auto;
  }

  // Gutter padding for a column
  .column-padding(@gutter-width:@gutter-width) {
    //@padding: (@gutter-width / 2);
    //padding-left: @padding;
    //padding-right: @padding;
    margin-right: @gutter-width;
  }

  // Column with padding
  .column(@x, @columns:@columns, @gutter-width:@gutter-width, @total-width:@total-width, @float:left) {
    .column-layout(@x, @columns, @total-width, @float);
    .column-padding(@gutter-width);
  }


  .omega(@query: 1) {

    &:nth-child(@{query}n + 1) {
      clear: both;
    }

    &:nth-child(@{query}n) {
      margin-right: 0;
    }

    .clearfix();
  }

  .omega-reset(@nth) {
    &:nth-child(@{nth}n) { margin-right: 20px;}
    &:nth-child(@{nth}n + 1) { clear: none; }
  }

  // Add x columns of margin-left to the element
  .push(@x:1) {
    margin-left: (@total-width/@columns) * @x;
  }

  // Add x columns of margin-right to the element
  // This really needs a better name
  .pull(@x:1) {
    margin-right: (@total-width/@columns) * @x;
  }
}

// Clear previously applied grid styles
.reset-grid(@margin:auto) {
  display: block;
  float: none;
  width: auto;
  margin-left: @margin;
  margin-right: @margin;
}


.grid();
