
.pagination {
  display: none;
}

.slide-item {
  display: block;
}

@media @mobile {

  .slide-item {
    display:none;
    width: 100%;

    &.active {
      display: block;
    }
  }

  /* Slideshow container */
  .slide-content {
    position: relative;
    margin: auto;
  }

  .pagination {
    display: block;
    width: 100%;
    background: @dot-border;
    margin-bottom: 10px;
    padding: 10px 0;
    text-align: center;
    clear: both;

    .lang-no & {
      background: none;
    }
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor:pointer;
    height: 20px;
    width: 20px;
    margin: 5px 7px;
    background-color: @grey-light;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;

    &.active,
    &:hover {
      background-color: @orange;

      .lang-no & {
        background-color: @nrk-red;
      }
    }
  }

  /* Fading animation */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 2s;
    animation-name: fade;
    animation-duration: 2s;
  }

  @-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }
}
