// Track

.track-info-bottom {

  .cover {
    width: 100%;

    @media @mobile {
      max-width: 70px;
      float: left;
    }
  }

  .info-container {
    width: 100%;

    @media @mobile {
      width: calc(~"100% - 80px");
      float: left;
    }
  }

  .info {
    width: 100%;
    min-height: 55px;
    padding: 15px 0 0;
    position: relative;

    @media @mobile {
      width: 45%;
      float: left;
      min-height: 70px;
      padding: 15px 0;
    }
  }

  .actions{
    display: flex;
    align-items: center;
    width: 100%;

    @media @mobile {
      width: calc(~"55% - 5px");
      float: left;
      margin: 15px 0 14px 5px;
    }
  }
}

.lang-no {
  .track.track-info-bottom {
    .info {
      padding: 15px 0 0;

      @media @mobile {
        padding: 20px 0 0;
      }
      
      .recomended {
        transform: translateY(-50%);
        
        @media @mobile {
          transform: translateY(0);
        }
      }
    }
  }
}
