// Track

.page-container > .track-container {
  border-bottom: solid 1px @grey-light;
  margin-bottom: 20px;

  @media @mobile {
    border-bottom: none;
    margin-bottom: 10px;
  }
}

.track {
  .clearfix();
  position: relative;

  @media @mobile {
    .grid(12, 100%, 10px);
  }

  .title,
  .artist {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .recomended {
    padding: 2px 6px 0;
    background-color: @pink;
    color: @white;
    font-size: 1.0rem;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    &.hidden {
      display: none;
    }
  }

  .title {
    a {
      font-size: @font-size-m;
      line-height: 2.0rem;
      color: @black;
      font-family: @regular;

      @media @mobile {
        font-size: @font-size-s;
      }
    }

    .ugenskanon {
      .gradient();
      height: 12px;
      width: 12px;
      margin: 2px 5px 2px 0;
      border-radius: 50%;
      display: inline-block;
      vertical-align: text-bottom;
    }
  }

  .artist {

    a {
      font-size: @font-size-s;
      line-height: 2.0rem;
      color: @grey;
      font-family: @semibold;

      @media @mobile {
        font-size: @font-size-xs;
      }
    }
  }

  .cover {
    position: relative;

    @media @mobile {
      .grid(12, 100%, 10px);
      .column(4);
    }

    &:hover {

      .play-overlay {
        opacity: 1;

        @media @mobile {
          opacity: 0;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .play-overlay {
    @size: 40px;
    .transition(all 400ms ease-in-out);
    opacity: 0;
    height: @size;
    width: @size;
    background-color: fade(@black, 40);
    border: solid 2px @white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: (@size / 2) * -1;
    margin-top: (@size / 2) * -1;
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: fade(@black, 60);
    }

    .icon-play {
      color: @white;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translate(-50%, -50%));

      &:before {
        font-size: 30px;
      }
    }

    .horizontal & {
      @size: 70px;
      height: @size;
      width: @size;
      margin-left: (@size / 2) * -1;
      margin-top: (@size / 2) * -1;

      .icon-play {

        &:before {
          font-size: 60px;

          @media @mobile {
            font-size: 30px;
          }
        }
      }

      @media @mobile {
        width: 50px;
        height: 50px;
        margin-left: 50px / 2 * -1;
        margin-top: 50px / 2 * -1;
      }
    }
  }

  &.now-playing {
    [class*="dr-icon-play"]:before {
      content: "\25ae";
    }

    .artist a, .title a, h1.title {
      color: @blue;
    }
  }

  .playlist-btn-container {
    .column(7);
    margin-right: 0;

    @media @mobile {
      width: 100%;
    }

    .playlist-play-btn {
      position: absolute;
      bottom: 10px;
      right: 0;
      margin: 0;

      @media @mobile {
        position: static;
        float: left;
        margin: 20px 0 20px;
      }
    }
  }
}

.lang-no {
  .page-container > .track-container {
    border-bottom: none;
  }

  .track {
    padding-bottom: 10px;
    border-bottom: 2px @nrk-red solid;

    .play-overlay {
      border-radius: 0;

      .icon-play:before {
        content: '';
        width: 24px;
        height: 24px;
        .nrk-icon-play("white");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .info {
      padding: 20px 0 0;

      .recomended {
        background-color: @nrk-red;
        .nrk-fonts(@style:semibolditalic);
      }

      .title{ 
        a {
          color: @nrk-blue;
          .nrk-fonts(@style:bookitalic);
          text-transform: uppercase;
          font-size: @font-size-m;
          line-height: 1.2;
        }

        .ugenskanon {
          display: inline-block;
          height: 16px;
          width: 16px;
          margin: 0px 3px 2px 0;
          border-radius: 0;
          background: transparent;
          background-image: url(../images/nrk/urort-favicon.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }

      .artist a {
        color: @nrk-blue;
        .nrk-fonts(@style:bolditalic);
        text-transform: uppercase;
        font-size: @font-size-m;
        line-height: 1.2;
      }
    }

    &.now-playing {
      .play-overlay {  
        .icon-play:before {
          .nrk-icon-pause("white");
        }
      }
      .info .title a,
      .info .title {
        color: @nrk-blue;
      }
    }
  }
  .horizontal .track .play-overlay {
    .icon-play:before {
      width: 40px;
      height: 40px;
    }
  }
}