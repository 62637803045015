.article-container {
  width: 83.33%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 8.33%;
  padding: 0 10px;

  @media @mobile {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .row {
    margin-top: 20px;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .narrow-content {
      margin: 0 40px;

      @media @mobile {
        margin: 0 10px;
      }
    }
  }

  h1 {
    font-size: 4.8rem;
    line-height: 5.4rem;
    color: @black;
    margin: 5px 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @media @mobile {
      font-size: 3.0rem;
      line-height: 3.6rem;
    }
  }

  .article-summery {
    font-size: 2.4rem;
    line-height: 3.0rem;
    color: @grey;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    @media @mobile {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  .article-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  figure {
    margin: 0 -10px;
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    figcaption {
      margin-top: 10px;
      clear: left;
      max-width: 600px;
      color: @grey;
      font-size: @font-size-s;
      line-height: @font-size-xl;

      @media @mobile {
        margin-left: 20px;
      }
    }

    .track-overlay {
      bottom: 48px;
    }
  }

  .article-abuot-section {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid @grey;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .col-1 {
      width: 40%;
      float: left;

      @media @mobile {
        width: 100%;
      }
    }

    .col-2 {
      width: 60%;
      float: left;
      text-align: right;

      @media @mobile {
        width: 100%;
        text-align: left;
        margin-top: 15px;
      }
    }
  }

  .article-author {
    font-size: @font-size-xs;
    line-height: @font-size-s;
    text-transform: uppercase;

    .article-author-name {
      display: inline;

      &:after {
        content: ", ";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }

  .article-date {
    font-size: @font-size-xs;
    line-height: @font-size-s;
    text-transform: uppercase;
    color: @grey;
  }

  .article-share {

    &.horizontal {
      margin-top: 20px;

      @media @mobile {
        text-align: center;
      }
    }

    li {
      display: inline-block;
      margin-left: 10px;
      list-style-type: none;
      vertical-align: baseline;
      text-transform: uppercase;

      @media @mobile {
        margin-left: 0;
        margin-right: 10px;
      }

      &:last-child {
        float: right;
      }
    }

    a {
      color: @blue;
      font-size: @font-size-xs;
      line-height: 2.8rem;
      vertical-align: baseline;
      text-decoration: underline;

      &:before {
        margin-right: 5px;
        min-width: @font-size-m;
        font-size: @font-size-m;
      }

      &[class*=dr-icon][class*=-boxed] {
        background-color: transparent;

        &:before {
          background-color: @blue;
          color: @white;
        }
      }

      &.follow-btn {
        color: white;
        background-color: #0072ff;
        display: inline-block;
        padding: 0 15px;
        text-decoration: none;
      }
    }
  }

  h3 {
    font-family: @semibold;
    font-size: @font-size-xl;
    line-height: 2.8rem;
    color: @black;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  p {
    font-family: @regular;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: @grey;
    margin: 0;
    font-size: @font-size-xl;
    line-height: 2.8rem;
    padding-bottom: 14px;

    @media @mobile {
      font-size: @font-size-l;
    }

    a {
      color: @grey;
      text-decoration: underline;
      display: inline;

      &:hover {
        color: @black;
      }
    }

    &.linkbox {
      display: flex;
      margin-top: 20px;
      margin-bottom: 30px;
      padding-bottom: 0;

      @media @mobile {
        margin-top: 10px;
        margin-bottom: 20px;
      }

      span {
        flex: 0 0 auto;
        color: @orange;
        text-transform: uppercase;
        font-family: @semibold;
        margin-right: 10px;

        .lang-no & {
          .nrk-fonts(@style:bold);
        }
      }

      a {
        color: @blue;
        text-decoration: underline;

        &:hover {
          color: @black;
        }

        .lang-no & {
          color: @nrk-blue;
        }
      }
    }
  }

  blockquote {
    p {
      font-family: @regular;
      font-style: normal;
      font-weight: 200;
      font-style: italic;
      font-size: @font-size-xxl;
      line-height: 4.2rem;
      color: @orange;
      word-wrap: break-word;

      @media @mobile {
        font-size: 2.4rem;
        line-height: 3.0rem;
        text-align: center;
      }

      &:before {
        content: "\201C";
        font: 700 33px/16px Georgia,serif;
        height: 30px;
        width: 30px;
        color: @white;
        background-color: @orange;
        display: block;
        font-size: 56px;
        line-height: 56px;
        margin-bottom: 20px;

        @media @mobile {
          margin-left: calc(~"50% - 15px");
        }
      }
    }
    cite {
      color: @grey-dark;
      font-size: @font-size-xs;
      line-height: 1;
      padding: 0;
      text-transform: uppercase;
      display: block;
      font-family: @regular;
      font-style: normal;
      font-weight: 400;
    }
  }

  .factbox {
    padding: 20px 20px 10px;
    background-color: @orange;
    color: @white;
    border: none;
    font-family: @regular;
    font-size: @font-size-m;
    line-height: 2.2rem;
    box-sizing: border-box;

    @media @mobile {
      padding: 10px 10px 0;
    }

    h3 {
      margin: -20px -20px 20px;
      border-top: 0 solid transparent;
      text-transform: uppercase;
      color: @white;
      padding: 20px;
      border-bottom: 1px solid hsla(0,0%,100%,.5);

      @media @mobile {
        margin: -10px -10px 10px;
        padding: 10px;
      }
    }

    ul {
      word-wrap: break-word;
      margin: 10px 0;
      list-style-type: disc;

      li {
        margin-left: 15px;
        margin-bottom: 10px;
        color: inherit;
        list-style: disc;
      }
    }
  }

  .moveout {
    width: 41.66666667%;

    &:nth-child(2n) {
      float: left;
      text-align: right;
      margin: 5px 40px 20px -120px;
    }

    &:nth-child(2n+1) {
      float: right;
      text-align: left;
      margin: 5px -120px 20px 40px;
    }

    @media @mobile {
      width: 100%;

      &:nth-child(2n) {
        float: none;
        text-align: left;
        margin: 0 0 16px;
      }

      &:nth-child(2n+1) {
        float: none;
        text-align: left;
        margin: 0 0 16px;
      }
    }
  }
}

.article-latest-list {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: @grey-dark;
  position: relative;

  @media @mobile {
    padding: 10px;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .list-header {
    padding: 15px 20px;
    margin: -15px -20px 20px -20px;
    position: relative;
    text-transform: uppercase;
    font-size: 3.0rem;
    line-height: 2.6rem;
    color: @orange;

    @media @mobile {
      padding: 5px 10px;
      margin: -5px -10px 10px -10px;
      font-size: @font-size-l;
      line-height: @font-size-xl;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      .gradient(left);
    }
  }

  ul {
    margin: 0 -10px;
  }

  .item {
    width: 25%;
    float: left;
    margin-bottom: 20px;
    padding: 0 10px;

    &:nth-child(4n+1) {
      clear: both;
    }

    @media @mobile {
      width: 50%;

      &:nth-child(4n+1) {
        clear: none;
      }

      &:nth-child(2n+1) {
        clear: both;
      }
    }

    a {
      display: block;
      padding-bottom: 20px;
      border-bottom: 1px solid @white;
      color: @white;

      &:hover {
        text-decoration: none;
      }

      .list-image {
        width: 100%;
      }

      .list-title {
        margin: 0;
        padding: 8px 0 0;
        font-size: @font-size-l;
        line-height: 2.4rem;
        font-family: @semibold;

        @media @mobile {
          font-size: @font-size-s;
          line-height: @font-size-xl;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .article-date {
      font-size: @font-size-xs;
      line-height: @font-size-s;
      text-transform: uppercase;
      color: @grey;
      margin-top: 6px;
    }
  }

  .readmore {
    color: @blue;
    font-size: @font-size-xs;
    line-height: 2.6rem;
    font-family: @regular;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;

    @media @mobile {
      top: 10px;
      right: 10px;
      line-height: @font-size-xl;
    }
  }
}

.lang-no {
  .article-container {  
    .article-summery {
      .nrk-fonts(@style:book);
      color: @nrk-black;
    }
  
    .article-abuot-section {
      border-bottom: 1px solid @nrk-red;
    }
  
    .article-author {
      .nrk-fonts(@style:bold);
      color: @nrk-blue;
    }
  
    .article-date {
      .nrk-fonts(@style:book);
      color: @nrk-blue;
    }
  
    .article-share {  
      a {
        color: @nrk-blue;

        &[class*=dr-icon][class*=-boxed]:before {
          background-color: transparent;
        }

        &::before {
          content: '';
          width: 14px;
          height: 14px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }

        &:hover {
          color: @nrk-red;

          &::before {
            background-color: transparent;
          }
        }

        &.share-mail {
          &::before {
            .nrk-icon-mail("blue");
          }
          &:hover {
            &::before {
              .nrk-icon-mail("red");
            }
          }
        }
        
        &.share-twitter {
          &::before {
            .nrk-icon-twitter("blue");
          }
          &:hover {
            &::before {
              .nrk-icon-twitter("red");
            }
          }
        }
        
        &.share-facebook {
          &::before {
            .nrk-icon-facebook("blue");
          }
          &:hover {
            &::before {
              .nrk-icon-facebook("red");
            }
          }
        }
      }
    }

    figure {
      figcaption {
        .nrk-fonts(@style:bookitalic);
        color: @nrk-black;
      }
    }
  
    h3 {
      .nrk-fonts(@style:bookitalic);
      color: @nrk-black;
    }
  
    p {
      .nrk-fonts(@style:book);
      color: @nrk-black;
  
      a {
        color: @nrk-black;
        text-decoration: none;
        border-bottom: 2px solid @nrk-red;
  
        &:hover {
          color: @nrk-black;
          text-decoration: none;
        }
      }
  
      // &.linkbox {
      //   display: flex;
      //   margin-top: 20px;
      //   margin-bottom: 30px;
      //   padding-bottom: 0;
  
      //   @media @mobile {
      //     margin-top: 10px;
      //     margin-bottom: 20px;
      //   }
  
      //   span {
      //     flex: 0 0 auto;
      //     color: @orange;
      //     text-transform: uppercase;
      //     font-family: @semibold;
      //     margin-right: 10px;
  
      //     .lang-no & {
      //       .nrk-fonts(@style:bold);
      //     }
      //   }
  
      //   a {
      //     color: @blue;
      //     text-decoration: underline;
  
      //     &:hover {
      //       color: @black;
      //     }
  
      //     .lang-no & {
      //       color: @nrk-blue;
      //     }
      //   }
      // }
    }
  
    blockquote {
      p {
        .nrk-fonts(@style:bookitalic);
        color: @nrk-red;
  
        &:before {
          background-color: @nrk-red;
        }
      }

      cite {
        color: @nrk-blue-dark;
      }
    }
  
    .factbox {
      background-color: @nrk-red;
  
      @media @mobile {
        padding: 10px 10px 0;
      }
  
      h3 {
        .nrk-fonts(@style:bookitalic);
        color: @white;
        text-align: center;
        margin: -20px -20px 20px;
        border-bottom: none;
      }

      p {
        color: @white;
      }
    }
  
    .moveout {  
      &:nth-child(2n) {
        text-align: left;
      }
    }
  }

  .article-latest-list {
    background-color: @nrk-blue-dark;
  
    .list-header {
      .nrk-fonts(@style:bookitalic);
      text-align: center;
      color: @white;
  
      &:after {
        display: none;
      }
    }
  
    .item {  
      a {
        border-bottom: none;
  
        .list-title {
          .nrk-fonts(@style:bold);
          line-height: 1.1;
        }
      }
  
      .article-date {
        .nrk-fonts(@style:book);
      }
    }
  
    .readmore {
      color: @white;
      text-decoration: none;
      border-bottom: 2px solid @nrk-red;

      &:hover {
        color: @white;
        text-decoration: none;
      }
    }
  }
}

