@lines: 2;

.playlist-embed {
  padding: 30px 0 2px 0;
  position: relative;
  margin: 10px 0 20px;

  &:before {
    content: "";
    background: url(../images/kk-logo-white.svg) 10px center no-repeat;
    background-size: 120px 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: @grey-dark;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    .gradient(left);
  }

  .playlist-embed-top-info {
		@media @content {
			display: inline-block;
			clear: both;
			width: 100%;
		}
  }

  .playlist-cover {
  	.column(3);
  	margin-right: 10px;

  	img {
  		width: 100%;
  	}

  	@media @content {
  		width: calc(30% - 10px);
  	}
    @media @content {
      width: calc(25%);
    }
  }

  .playlist-info {
  	.column(5);
  	margin-right: 0;

		@media @content {
			width: calc(70% - 0px);
		}

    p {
      padding-bottom: 0px;

      @media @mobile {
        display: block; 
        display: -webkit-box;
        height: @lines; 
        -webkit-line-clamp: @lines;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  button.playlist-play-btn {
  	margin-top: 10px;

		@media @content {
			float: left;
			margin-left: 0;
		}
  }

  .embedded-item {
		.clearfix();
		background: @dot-border;
		padding-bottom: 5px;
		margin-bottom: 0px;

  	.track-info-right-long {
			.cover {
				display: none;
			}

	    .info {
	      min-height: 70px;
	      position: relative;
	      .column(8);

	      .title {
	      	left: calc(50%);
	      }

		    @media @mobile {
		    	width: calc(50% - 10px);
		    	min-height: 60px;
		    }
	    }

	    .actions {
		    .column(4);
		    margin-top: 20px;
		    margin-right: 0;
		    float: right;

		    @media @mobile {
		    	width: calc(50%);
		    }
		  }
  	}
  }

  .list-track-container {
  	margin-bottom: 0px;
  	clear: both;
  }

  .show-more {
  	padding-bottom: 1.5rem;
    margin-top: 1.5rem;

    a, span {
    	font-size: 1.5rem;
    }
  }
}