// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix() {
  *zoom:1;

  &:before,
  &:after {
    content:"";
    display:table;
  }
  &:after {
    clear:both;
  }
}
