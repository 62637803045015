//toggle

@lines: 2;

.list-track-toggle {
  .recommendation {
    background: @dot-border;
    padding-bottom: 20px;

    @media @mobile {
      background: none;
    }

    .info {
      top: 30%;

      .reviewer {
        @media @content {
          display: block;
          display: -webkit-box;
          height: @lines;
          -webkit-line-clamp: @lines;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.toggle-bar {
  margin-bottom: 20px;
  width: 100%;
  float: left;
  position: relative;
  padding-top: 60px;

  @media @mobile {
    margin-bottom: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    .gradient(left);
    height: 100px;
    width: 100vw;
    left: calc(~"(100vw - 940px) / 2 * -1");
    z-index: -1;
    bottom: 0;

    @media @content {
      left: -10px;
    }
    @media @mobile {
      height: 110px;
    }
  }

  .toggle-item {
    width: calc(~"25% - 2px");
    float: left;
    margin-right: 1px;
    text-transform: uppercase;
    background: @grey-light;
    text-align: center;
    color: @grey;
    font-family: @semibold;
    font-size: @font-size-l;
    cursor: pointer;
    height: 40px;
    line-height: 40px;

    @media @mobile {
      min-height: 50px;
      padding: 10px 5px;
      line-height: normal;
      height: auto;
      font-size: @font-size-xs;

      .list-track-toggle & {
        width: calc(~"33.33% - 1px");
      }
    }

    &:hover {
      text-decoration: none;
    }

    &.active {
      background: @white;
      color: @black;
    }
  }
}

.show-more {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 10px;

  a {
    font-family: @regular;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: @font-size-l;
    color: @grey;

    .dr-icon-add {
      color: @blue;
      font-size: 2.4rem;
      margin-right: 10px;
    }
  }
}

.lang-no {
  .list-track-toggle {
    .recommendation {
      background: none;
    }
  }

  .toggle-bar {
    &:after {
      background-color: @nrk-red;
      background-image: url(../images/nrk/nrk_bg-element.png);
      background-position: center;
      background-size: 120% auto;
      background-repeat: no-repeat;
    }

    .toggle-item {
      .nrk-fonts(@style:semibolditalic);
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      color: @white;
      line-height: 40px;

      &.active {
        background: @white;
        color: @nrk-red;
      }

      @media @mobile {
        width: calc(~"50% - 2px");
        padding: 5px;
        margin-top: 1px;
        min-height: 40px;
      }
    }
  }

  .show-more {
    a {
      .nrk-fonts(@style:heavyitalic);
      text-decoration: none;
      font-size: @font-size-xl;
      line-height: 24px;
      color: @nrk-black;

      .dr-icon-add {
        color: @nrk-yellow;

        &:before {
          content: "";
          width: 24px;
          height: 24px;
          .nrk-icon-plus("yellow");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
