// Two col layout for frontpage etc.

.twocol {
  overflow: hidden;

  .col-one {
    .column(8);

    .login & {
      .column(6);

      @media @mobile {
        width: 100%;
      }
    }

    @media @mobile {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .col-two {
    .column(4);
    margin-right: 0;

    .login & {
      .push(2);


      @media @mobile {
        .push(0);
      }
    }

    @media @mobile {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.twocol-split {
  .clearfix;
  margin-bottom: 20px;

  .col-one {
    .column(6);

    @media @mobile {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .col-two {
    .column(6);
    margin-right: 0;

    @media @mobile {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
