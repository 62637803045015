.search-teaser {
  .grid(12, 100%, 10px);
  .transition(background-color 200ms ease-in-out);
  .clearfix;
  background-color: @white;
  margin-bottom: 2px;
  cursor: pointer;

  &:hover {
    background-color: lighten(@grey-light, 5%);
  }

  .cover,
  .picture {
    width: 40px;
    float: left;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }

  .picture {
    padding: 5px;

    img {
      border-radius: 50%;
    }
  }

  .info {
    margin-top: 5px;
  }

  .artist {
    font-size: @font-size-xs;
    color: @grey;
    font-family: @semibold;
    text-decoration: none;

    .lang-no & {
      .nrk-fonts(@style:bookitalic);
    }
  }
  .recommendation {
    width: 58%;
    margin-top: 0;
    float: right;
    margin-right: 10px;

    .info {
      margin-top: 0;

      .text {
        display: inline;
      }

      .reviewer {
        display: inline;
      }
    }
  }

  &.artist-search {
    .artist {
      line-height: 40px;
    }
  }

  .title {
    font-size: @font-size-s;
    color: @black;
    font-family: @regular;
    text-decoration: none;

    .lang-no & {
      .nrk-fonts(@style:bookitalic);
    }
  }

  .btn-settings {
    margin-top: 0;
  }

  .search-article & {

    .cover {
      height: 40px;
      width: auto;
    }

    .info {
      float: inherit;
    }
  }
}

.admin-section .search-article .search-title {
  line-height: 40px;
}
