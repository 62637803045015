header {
  margin: 25px 0 20px;
  position: relative;
  padding-bottom: 6px;

  @media @content {
    .clearfix;
    text-align: center;
    margin: 10px 0 10px;
    padding-bottom: 16px;
  }
}

.page-logo {
  font-size: 4.8rem;
  font-family: @bold;
  line-height: 35px;
  color: @black;
  text-transform: uppercase;
  margin-bottom: 25px;
  display: inline-block;

  @media @content {
    font-size: 3.8rem;
    margin-bottom: 10px;
    display: block;
  }

  @media @mobile {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.menu {
  float: right;
  margin-top: 5px;

  @media @content {
    float: none;
    display: inline-block;
    margin-top: 0;
  }

  @media @mobile {
    width: 100%;
  }

  .menu-item {
    margin-left: 18px;
    float: left;
    height: 25px;
    line-height: 25px;;
    color: @blue;

    &.divider {
      width: 2px;
      background-color: @grey-light;
    }

    &:last-child {
      margin-right: 0;
    }

    &.profile-create {
      color: @white;
      background: @blue;
      padding: 0 10px;

      @media @mobile {
        display: inline-block;
      }
    }

    &.profile-login,
    &.profile-view {
      position: relative;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      margin-right: 5px;
      vertical-align: middle;
      font-size: @font-size-s;

      &.dr-icon-like {
        color: @pink;
      }

      &.fa {
        font-size: 2.1rem;
      }
    }

    &.profile-view {
      @media @mobile {
        padding-left: 0px;

        &:before {
          display: none;
        }
      }
    }

    @media @mobile {
      margin-right: 0;
      float: none;

      &.divider {
        width: 100%;
        height: 2px;
        margin: 10px 0;
        display: block;
      }
    }
  }
}

.lang-no {
  .nrkno-header {
    position: relative;
    background-color: #000; color: #fff; padding: 5px 0 5px 0;
    width: 100vw;
    left: calc(~"(100vw - 940px) / 2 * -1");

    @media @content {
      left: -10px;
    }
  }

  .nrkno-header a, .nrkno-header a:link, .nrkno-header a:visited, .nrkno-header a:hover {
    color: #fff;
    background: url("../images/nrk/nrk_back.png") no-repeat scroll 0 50% transparent;
    text-decoration:none;
    padding: 0 0 0 30px;
    text-indent: 0;
    width: 10em;
  }

  header {
    padding: 20px 0;
    margin: 0 0 40px;
    border-bottom: none;
    position: relative;

    @media @mobile {
      padding: 10px 0 15px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100vw;
      height: 5px;
      left: calc(~"(100vw - 940px) / 2 * -1");
      background-image: url(../images/nrk/nrk_bg-element.png);
      background-position: center;
      background-size: 120% auto;
      background-repeat: no-repeat;

      @media @content {
        left: -10px;
      }
      @media @mobile {
        height: 5px;
      }
    }
  }

  .page-logo {
    margin-bottom: 0;
    font-size: 0;
    line-height: 60px;

    img {
      height: 60px;

      @media @mobile {
        height: 40px;
      }
    }

    &:hover {
      text-decoration: none;
    }

    @media @mobile {
      margin: 0 0 10px;
      line-height: 40px;
    }
  }

  .menu {
    margin: 17px 0;

    .menu-item {
      color: @nrk-black;
      .nrk-fonts(@style:bookitalic);
      line-height: 26px;

      &.profile-create {
        background: @nrk-yellow;
        color: @nrk-black;
      }

      &.go-to-faq {
        background: @nrk-yellow;
        color: @nrk-black;
        padding: 0 10px;
        display: inline-block;
      }

      i {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: -2px 5px 0 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.icon-search {
          .nrk-icon-search();
        }

        &.icon-favorite {
          .nrk-icon-heart("red");
        }

        &.icon-profile {
          .nrk-icon-profile();
        }

        &.icon-login {
          .nrk-icon-login();
        }
      }
    }

    &.menu-desktop {
        @media @small-mobile {
            display: none;
        }
    }

    &.menu-mobile {
        display: none;

        @media @small-mobile {
            display: block;

            .menu-item {
                margin-left: 0;
            }

            .row {
                display: flex;
                justify-content: space-evenly;

                &.row2 {
                    margin-top: 10px;
                }
            }
        }
      }
  }
}
