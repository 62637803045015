.placeholder(@rules) {
  &::-webkit-input-placeholder {
      @rules();
  }
  &:-moz-placeholder {
      @rules();
  }
  &::-moz-placeholder {
      @rules();
  }
  &:-ms-input-placeholder {
      @rules();
  }
}
