// Playlist

.playlist-info-bottom {

  .cover {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }

  .title {
    font-family: @semibold;
  }

  .lang-no & {
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5px;
      min-width: 90%;
      max-width: 100%;
      background-color: #000;
      text-align: center;
      transform: translate(-50%, -50%);
      .nrk-fonts(@style:bolditalic);
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
}
