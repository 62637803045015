.track-embed-iframe {
  padding: 30px 0 2px;
  position: relative;
  margin: 0;

  .cover {
    width: 110px;

    .play-overlay {
      .icon-play:before {
        background-image: url(../images/icon-play-white.svg);
        content: '';
        background-position: left 13px center;
        background-repeat: no-repeat;
        background-size: auto 24px;
        width: 36px;
        height: 36px;
      }
    }

    @media @mobile {
      max-width: 110px;
      width: 110px;
    }
  }

  .track.now-playing {
    .cover {
      .play-overlay {
        .icon-play:before {
          background-image: url(../images/icon-pause-white.svg);
          background-position: center;
          background-size: auto 16px;
        }
      }
    }
  }

  .info-container {
    @media @mobile {
      width: calc(~"100% - 120px");
    }
  }

  .info {
    width: 100%;
    min-height: 55px;
    padding: 10px 0 0;
    position: relative;
  }

  .actions {
    margin: 0;
    @media @mobile {
      width: 100%;
    }

    .btn.btn-share {
      position: absolute;
      top: 6px;
      right: 0;
      width: 40px;

      &[class*="dr-icon-share"]:before {
        content: '';
        background-image: url(../images/icon-share-black.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px auto;
        width: 16px;
        height: 16px;
      }

      &[class*="dr-icon-share"]:hover:before {
        background-image: url(../images/icon-share-blue.svg);
      }

      .social-share {
        right: 35px;
        top: 0px;
      }
    }
  }

  #audio-player {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: #fff;
    color: #191919;
    border-top: none;
    box-shadow: none;
    z-index: 0;

    @media @mobile {
      min-height: auto;
      padding: 0;
    }

    .page-container {
      max-width: 100%;

      @media @content {
        padding: 0;
      }

      .playing-actions {
        float: left;
        width: 45px;
        margin-right: 20px;
        margin-right: 0;
        float: right;
        text-align: right;

        button#playpause,
        .dr-icon-play,
        .dr-icon-pause {
          margin: 5px 5px 0 0;
          background-color: transparent;
          border: 2px solid #3c3c3b;
          color: #3c3c3b
        }
        
        .dr-icon-play:before {
          background-image: url(../images/icon-play-black.svg);
          content: '';
          background-position: left 13px center;
          background-repeat: no-repeat;
          background-size: auto 24px;
          width: 36px;
          height: 36px;
        }

        .dr-icon-play.loader:before {
          background-image: url(../images/icon-loader-black.svg);
          content: '';
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 24px;
          width: 36px;
          height: 36px;
        }
        
        .dr-icon-pause:before {
          background-image: url(../images/icon-pause-black.svg);
          content: '';
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 16px;
          width: 36px;
          height: 36px;
        }
      }

      .progress {
        float: left;
        width: calc(~"100% - 65px");
        margin-right: 20px;
        position: relative;
        height: 55px;

        .barUnderlay {
          margin: 0;
          @media @mobile {
            margin: 15px 0 0;
          }

          .barBackground {
            background: #3c3c3b;
          }

          .barMarker {
            top: 5px;
            background: rgb(0, 114, 255);
            @media @mobile {
              top: 15px;
            }
          }

          .barCurrent {
            top: 10px;
            background: rgb(0, 114, 255);
            @media @mobile {
              top: 23px;
            }
          }
        }

        .time {
          top: 25px;
        }
      }
    }

    #playprevious,
    #playnext {
      display: none;
    }
  }
}

.lang-no {
  .track-embed-iframe {  
    .cover {
      .play-overlay {
        .icon-play:before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url('../images/nrk/nrk_icon-play-white.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  
    .track.now-playing {
      .cover {
        .play-overlay {
          .icon-play:before {
            background-image: url('../images/nrk/nrk_icon-pause-white.svg');
            background-position: center;
            background-size: contain;
          }
        }
      }
    }

    .info {
      padding-top: 10px;
    }
  
    .actions {
      .btn.btn-share {
  
        &[class*="dr-icon-share"]:before {
          content: '';
          background-image: url('../images/nrk/nrk_icon-more-black.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
  
        &[class*="dr-icon-share"]:hover:before {
          background-image: url('../images/nrk/nrk_icon-more-red.svg');
        }

        .social-share {
          top: -25px;
        }
      }
    }
  
    #audio-player {
      .page-container {  
        .playing-actions {
  
          button#playpause,
          .dr-icon-play,
          .dr-icon-pause {
            margin: 5px 5px 0 0;
            background-color: transparent;
            border: 0;
            color: #515961;
          }
          
          .dr-icon-play:before {
            background-image: url('../images/nrk/nrk_icon-play-black.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
  
          .dr-icon-play.loader:before {
            background-image: url(../images/icon-loader-black.svg);
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 24px;
          }
          
          .dr-icon-pause:before {
            background-image: url('../images/nrk/nrk_icon-pause-black.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
  
        .progress {
          .barUnderlay {  
            .barBackground {
              background: #515961;
            }
  
            .barMarker {
              background: #ec1b2f;
            }
  
            .barCurrent {
              background: #ec1b2f;
            }
          }
        }
      }
    }
  }
}