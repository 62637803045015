// Track

.track-info-right {

  .cover {
    max-width: 110px;
    float: left;
    margin-right: 10px;

    @media @mobile {
      max-width: 70px;
    }
  }

  .info-container {
    width: calc(~"100% - 120px");
    float: left;

    @media @mobile {
      width: calc(~"100% - 80px");
    }
  }

  .info {
    width: 100%;
    min-height: 55px;
    padding: 15px 0 0;
    position: relative;

    @media @mobile {
      width: 45%;
      float: left;
      min-height: 70px;
      padding: 15px 0;
    }
  }

  .actions{
    display: flex;
    align-items: center;
    width: 100%;

    @media @mobile {
      width: calc(~"55% - 5px");
      float: left;
      margin: 15px 0 14px 5px;
    }
  }

  &.track-admin {
    .cover {
      max-width: 60px;
      float: left;
      margin-right: 10px;
    }

    .info-container {
      width: calc(~"100% - 120px");
      float: left;
    }

    .info {
      width: 100%;
      min-height: 60px;
      padding: 10px 0;
      position: relative;
    }
  }
}

.lang-no {
  // .track-info-right {
  //   border-bottom: none;
  //   padding-bottom: 0;

  //   &.track-admin {
  //     .info {
  //       padding: 5px 0;
  //     }
  //   }
  // }
}
