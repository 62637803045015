// Shared input style.
input,
textarea {
  width: 100%;
  background-color: @grey-light;
  border: none;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  font-size: @font-size-s;
  outline: none;
  -webkit-border-radius: 0;
  border-radius: 0;

  &:focus {
    background-color: fade(@grey-light, 50);
  }

  .placeholder({
    color: @grey;
  });

  &[type="submit"] {
    .submit-btn();
  }

  &.error {
    border: 2px solid @orange;
  }
}

select {
  width: 100%;
  background-color: #e5e5e5;
  border: none;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  font-size: 1.4rem;
  outline: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  background-image: url(../images/nrk/nrk_icon-arrow.svg);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center right 15px;
}

// Button style.
.page-container button {
  .submit-btn();

  .dre-top-navigation__list & {
    float: none;
  }
}

.page-container button[class*="dr-icon"] {
  font-size: 1.2rem;
}

// Textarea style.
textarea {
  line-height: inherit;
  min-height: 120px;
  padding: 10px;
  max-width: 100%;
  resize: none;
}

// Label Style.
label {
  font-family: @regular;
  font-size: @font-size-xs;
  margin-bottom: 5px;
  color: @grey;
  text-transform: uppercase;
  font-weight: normal;
  display: block;

  .lang-no & {
    .nrk-fonts(@style:book);
  }
}

// Form item style.
.form-item {
  margin-bottom: 20px;
  position: relative;
  text-align: left;

  .description {
    font-size: @font-size-xs;
    color: @grey;
  }

  // Status message.
  .message {
    padding: 5px 10px;
    background-color: @grey-dark;
    color: @white;
    position: absolute;
    top: calc(~"100% + 4px");
    left: 0;
    z-index: 10;
    font-size: @font-size-xs;

    .lang-no & {
      background-color: @nrk-blue-dark;
    }

    &:after {
      content: "";
      position: absolute;
      top: -8px;
      left: 10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid @grey-dark;
    }
  }

  // Instrumental check
  &.instrumental {
    width: 100%;
    input[type="checkbox"] {
      width: auto;
      height: auto;
      float: left;
      margin-right: 5px;
    }
  }

  // Terms and conditions.
  &.terms-agreement {
    width: 100%;
    input[type="checkbox"] {
      width: auto;
      height: auto;
      float: left;
      margin-right: 5px;
    }

    &:has(.error) {
      label {
        color: @orange;
      }
    }
  }

  &.disabled {
    input {
      background-color: lighten(@grey-light, 5%);
      color: lighten(@grey, 10%);
      cursor: not-allowed;

      .placeholder({
        color: lighten(@grey, 10%);
      });
    }
    button {
      background-color: @grey-light;
      cursor: not-allowed;
    }
  }

  // Clone field.
  &.multiple {
    input {
      padding-right: 54px;
      margin-bottom: 5px;
    }

    button {
      position: absolute;
      bottom: 5px;
      right: 0;
      margin-bottom: 0;

      &:before {
        padding-right: 0;
      }
    }
  }
}

.field-group {
  background-color: @grey-light;
  padding: 10px;

  &.error {
    border: 2px solid @orange;
    padding: 8px;
  }
}

// radios.
.radio-container {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    float: left;
    margin-right: 10px;
    width: auto;
    height: inherit;
  }

  label {
    margin: 0 0 0 23px;
    word-break: break-word;
  }
}

.forgot-password {
  clear: both;
  width: 100%;
  margin-bottom: 5px;

  a {
    text-decoration: underline;
    text-transform: uppercase;
    color: @blue;

    .lang-no & {
      color: @nrk-blue;
    }
  }
}

// Wrapper for admin buttons.
.admin-action-btn {
  .clearfix;
  clear: both;
  margin-top: 20px;
  margin-bottom: 20px;
}

.img-upload {
  overflow: hidden;
}

.img-upload,
.track-upload {
  .input-file {
    position: relative;
    height: 40px;

    .message {
      text-transform: none;
    }

    .button {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 30px;
      margin: 0;
      padding: 0 10px;
      line-height: 33px;
      background-color: @grey-dark;
      color: @white;
      cursor: pointer;

      .lang-no & {
        background-color: @nrk-blue-dark;
      }

      &:hover {
        opacity: 0.9;
      }

      input {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        font-size: 95px;
        cursor: pointer;
        opacity: 0;
        height: 30px;
      }
    }

    & > input {
      width: calc(~"100% - 70px");
      float: left;
    }
    & > form {
      width: calc(~"100% - 70px");
      float: left;
    }

    &.img-delete {
      width: calc(~"100% - 120px");
      float: left;

      & > input {
        width: auto;
        float: left;

        &[type="text"] {
          width: 100%;
        }
      }

      .button {
        right: 5px;
      }
    }
  }

  button.btn-delete {
    margin-top: 0;
  }

  &.img-first {
    padding: 10px 10px 20px;
    background: @grey-light;

    input {
      background: @white;
    }
  }
}
.track-upload {
  .input-file {
    & > input {
      width: 100%;
      float: left;
    }
  }
}
