.track-info-page {

  @media @mobile {
    text-align: center;
  }

  &-top {
    position: relative;
    overflow: hidden;
  }

  .cover-page {
    .column(5);
    margin-bottom: 10px;
    position: relative;
    z-index: 1;

    .play-overlay {
      opacity: 1;
      cursor: pointer;
      @size: 150px;
      height: @size;
      width: @size;
      margin-left: (@size / 2) * -1;
      margin-top: (@size / 2) * -1;

      @media @mobile {
        display: block;
      }

      .icon-play {
        &:before {
          font-size: 120px;
          line-height: 120px;
        }
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    @media @mobile {
      width: 100%;
      margin-bottom: 0;
      float: none;
    }
  }

  .track-info {
    .column(7);
    margin-right: 0;
    padding: 20px 20px 0 0;
    z-index: 2;
    position: relative;

    .title  {
      font-size: @font-size-xxl;
      color: @black;
      font-family: @semibold;
      margin: 0 0 10px 0;


      .ugenskanon {
        height: 20px;
        width: 20px;
        margin: 10px 10px 10px 0;

        @media @mobile {
          height: 12px;
          width: 12px;
          margin: 5px 5px 5px 0;
        }
      }
    }

    .artist a {
      font-size: @font-size-l;
      color: @grey;
      margin: 0 0 10px 0;

      @media @mobile {
        font-size: @font-size-s;
      }
    }

    .title,
    .artist {
      white-space: inherit;
      overflow: inherit;
      text-overflow: inherit;
    }

    @media @mobile {
      .column-centered(10);
      background: @white;
      margin-top: -50px;
      padding: 15px 10px 10px;
      display: inline-block;
      text-align: left;
      float: none;

      .title  {
        font-size: @font-size-l;
      font-family: @regular;
      }

      .artist  {
        font-size: @font-size-s;
      }

      .recomended {
        left: 10px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    width: 40%;
    margin-bottom: 20px;
    margin-top: 20px;

    .btn {

      .text {
        font-size: @font-size-xs;
      }
    }

    @media @mobile {
      .column-centered(10);
      float: none;
      margin-top: 20px;
    }
  }

  .label {
    width: 100%;
    float: left;

    .ugenskanon-label {
      background: @grey-dark;
      color: @white;
      display: inline-block;
      position: relative;
      line-height: 50px;
      padding-right: 10px;

      &:before {
        content: "";
        display: block;
        background: url(../images/kk-logo.svg) center no-repeat;
        background-size: 40px;
        height: 40px;
        width: 40px;
        padding: 5px 10px;
        float: left;
      }
    }

    @media @mobile {
      text-align: center;
    }
  }

  .recommendation {
    .column(7);
    margin-right: 0;
    margin-bottom: 15px;

    .text {
      font-size: @font-size-s;
    }

    @media @mobile {
      .column-centered(10);
      text-align: left;
      float: none;

      .info {
        .transform(translateY(0));
        position: inherit;
        top: inherit;
        left: inherit;
        float: left;
        width: calc(~"100% - 50px");

        .text {
          font-size: @font-size-xs;
        }
      }
    }
  }
}

.meta-info {
  width: 100%;
  background-color: @grey-dark;
  padding: 20px;
  text-align: left;

  @media @mobile {
    padding: 10px;
    margin-bottom: 10px;
  }

  &> ul {

    &> li {
      margin-bottom: 10px;
      font-size: @font-size-xs;
      font-family: @semibold;
      text-transform: uppercase;
      color: @white;
      border-top: 1px solid @white;
      padding-top: 5px;
      overflow: hidden;

      &:first-child {
        border-top: none;
      }

      &> ul {
        display: inline-block;
        float: right;

        &> li {
          font-size: @font-size-xs;
          font-family: @regular;
          text-transform: none;
          text-align: right;
        }
      }
    }
  }
}

.lang-no {
  .track.track-info-page {
    border-bottom: none;

    .cover-page {
      .play-overlay {
        border-width: 4px;
        height: 100px;
        width: 100px;
        margin-left: -50px;
        margin-top: -50px;

        .icon-play {
          left: 53%;
          
          &:before { 
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .track-info {
      @media @mobile {
        padding: 20px 10px 10px;
      }
      .title {
        .nrk-fonts(@style:bolditalic);
        text-transform: uppercase;

        .ugenskanon {
          height: 20px;
          width: 20px;
          margin: 0px 3px 12px 0;
        }

        @media @mobile {
          .nrk-fonts(@style:bookitalic);
        }
      }

      .actions {
        padding-bottom: 10px;
        border-bottom: 2px @nrk-red solid;

        @media @mobile {
          margin: 20px auto 0;
        }
      }
    }

    .label {
      margin-bottom: 10px;

      .ugenskanon-label {
        background: @nrk-blue-dark;
        .nrk-fonts(@style:bolditalic);
        line-height: 50px;
  
        &:before {
          background-image: url(../images/nrk/urort-icon.png);
          background-size: 30px;
        }
      }
    }

    .recommendation {  
      @media @mobile {
        margin: 10px auto 0;
      }

      .info {
        padding: 0;
      }
    }
  }

  .meta-info {  
    &> ul {
      &> li {
        .nrk-fonts(@style:bolditalic);
        &> ul {
          &> li {
            .nrk-fonts(@style:book);
          }
        }
      }
    }
  }
}