.list-admin-toggle {
  .clearfix();
  margin-top: 20px;

  @media @mobile {
    margin-top: 10px;
  }
}

.admin {
  position: relative;
  margin-bottom: 65px;

  #admin-track-target {
    min-height: 120px;
  }
  @media @content {
    .col-two,
    .col-three {
      margin-top: 40px;
    }
  }

  .admin-section {
    .grid(12, 100%, 20px);
    width: 100%;
    background: @dot-border;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media @mobile {
      .grid(12, 100%, 10px);
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 40px;
    }

    &:last-child {
      background: transparent;
      padding-bottom: 0;
    }

    .lang-no & {
      &.battle {
        display: none;
      }
    }
  }

  .admin-width-m {
    .column(5);

    @media @mobile {
      .column(10);
    }
  }

  .admin-width-l {
    .column(6);

    @media @mobile {
      .column(12);
    }
  }

  .admin-width-xl {
    .column(12);

    @media @mobile {
      .column(10);
    }
  }

  .admin-width-xs {
    .column(1);

    button {
      width: 100%;
      padding: 0;
      text-align: center;
    }

    @media @mobile {
      .column(2);
    }
  }

  li {
    .clearfix();

    .form-item:last-child,
    .item:last-child {
      margin-right: 0;
    }

    @media @mobile {
      border-bottom: 1px solid @grey-light;
      margin-bottom: 10px;
    }
  }

  .versus {
    margin-right: 100px;
    position: relative;

    &:after {
      content: "VS";
      text-align: center;
      font-family: @bold;
      font-size: @font-size-l;
      position: absolute;
      right: -60px;
      top: 30px;
    }

    @media @mobile {
      margin-right: 0;
    }

    // .lang-no & {.nrk-fonts(@style:heavyitalic);}
  }

  .autocomplete-small {
    width: 100%;
  }

  .expert-list {
    .item {
      background: @dot-border;
      padding-bottom: 20px;
      margin-bottom: 20px;
      overflow: hidden;

      .lang-no & {
        background: transparent;
        border-bottom: 2px solid @nrk-red;
      }

      &:first-child {
        &:before {
          content: "";
          margin-bottom: 20px;
          width: 100%;
          height: 1px;
          background: @dot-border;

          .lang-no & {
            background: transparent;
            border-bottom: 2px solid @nrk-red;
            height: 0;
          }
        }
      }

      @media @mobile {
        border: none;
      }

      .picture {
        .column(2);

        @media @mobile {
          width: 13%;
          margin-right: 10px;
        }

        img {
          border-radius: 50%;
          width: 100%;
        }
      }

      .info {
        float: left;
        margin-top: 10px;

        @media @mobile {
          margin-top: 0;
        }

        .name {
          font-size: @font-size-m;
          color: @black;
          font-family: @regular;

          .lang-no & {
            .nrk-fonts(@style:bookitalic);
          }
        }

        .add-by {
          font-size: @font-size-s;
          color: @grey;
          font-family: @semibold;

          .lang-no & {
            .nrk-fonts(@style:bolditalic);
          }
        }
      }

      button {
        margin: 10px 0 0 0;

        @media @mobile {
          margin: 0;
        }
      }
    }
  }

  .playlist-list {
    .item {
      background: @dot-border;
      padding-bottom: 20px;
      margin-bottom: 20px;
      overflow: hidden;

      .lang-no & {
        background: transparent;
        border-bottom: 2px solid @nrk-red;
      }

      &:first-child {
        margin-top: 20px;
      }

      @media @mobile {
        border: none;
      }

      .picture {
        .column(2);

        @media @mobile {
          .column(3);
        }

        img {
          width: 100%;
        }
      }

      .info {
        .column(7);
        float: left;
        margin-top: 10px;

        @media @mobile {
          .column(6);
          margin-top: 0;
          margin-bottom: 20px;
        }

        .title {
          font-size: @font-size-m;
          color: @black;
          font-family: @regular;

          .lang-no & {
            .nrk-fonts(@style:bookitalic);
          }
        }

        .add-by {
          font-size: @font-size-s;
          color: @grey;
          font-family: @semibold;

          .lang-no & {
            .nrk-fonts(@style:bolditalic);
          }
        }
      }

      button {
        margin: 10px 0 0 0;

        @media @mobile {
          margin: 0;
        }
      }
    }
  }

  .playlist-toggle {
    overflow: hidden;

    h2 {
      display: block;
      width: calc(~"50% - 1px");
      color: @black;
      background-color: @white;
      cursor: default;
      float: left;
      text-align: center;
      height: 44px;
      font-size: @font-size-m;
      line-height: 40px;
      border-top: 4px solid @grey-dark;
      border-right: 1px solid @grey-dark;

      &:last-child {
        border-right: none;
      }

      &.block-toggler {
        color: @grey;
        background-color: @grey-light;
        cursor: pointer;
      }
    }
  }

  .track-list {
    .item {
      background: @dot-border;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .lang-no & {
        background: transparent;
      }

      &:first-child {
        margin-top: 20px;
      }

      @media @mobile {
        border: none;
      }

      .cover {
        width: 60px;
        margin-right: 20px;
        float: left;

        img {
          width: 100%;
        }
      }

      .info {
        .column(5);

        .lang-no & {
          padding-top: 0;
        }

        @media @mobile {
          margin-top: 0;
        }

        .title {
          font-size: @font-size-m;
          color: @black;
          font-family: @regular;

          .lang-no & {
            .nrk-fonts(@style:bookitalic);
          }
        }

        .artist {
          font-size: @font-size-s;
          color: @grey;
          font-family: @semibold;

          .lang-no & {
            .nrk-fonts(@style:bolditalic);
          }
        }

        .status {
          float: left;
          background-color: @grey-dark;
          color: @white;
          margin-right: 10px;
          padding: 4px 6px 2px;
          position: inherit;

          &.hidden {
            display: none;
          }
        }
      }

      .form-item {
        margin-bottom: 0;
      }

      .track-radio {
        .column(4);
        margin-right: 0;
      }

      .track-textarea {
        .column(12);
        margin-right: 0;
        margin-top: 15px;
      }

      button {
        margin: 0;
      }
    }

    &.battle-list {
      margin-bottom: 20px;

      .form-item {
        margin-bottom: 0;
      }

      .item {
        position: relative;

        &.week-number {
          margin-top: 0;
          background: transparent;
          font-family: @bold;
          font-size: @font-size-l;

          .lang-no & {
            .nrk-fonts(@style:heavyitalic);
          }
        }

        .info {
          .column(8);
          margin-right: 0;
          margin-bottom: 0;
          min-height: 0;
        }

        .status {
          float: right;
          background-color: @grey-dark;
          color: @white;
          line-height: 20px;
          padding: 5px 10px 2px;
          font-size: @font-size-xs;
          position: absolute;
          top: 20px;
          right: 0;

          i.fa {
            font-size: 15px;
            padding-right: 5px;
          }
        }

        .lang-no & {
          .track.track-admin {
            border-bottom: none;
          }
        }
      }
    }

    .autocomplete-small {
      .item {
        background: transparent;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .track-form-info {
    .cover {
      width: 60px;
      margin-right: 20px;
      float: left;

      @media @mobile {
        margin-right: 10px;
      }
    }

    .info {
      .column(8);
      padding: 0;

      @media @mobile {
        .column(7);
      }
    }

    @media @mobile {
      margin-bottom: 20px;
    }
  }

  .article-list {
    .form-item {
      &.track-art {
        margin-right: 0;
      }
    }

    li {
      background-color: @grey-light;
      padding: 10px 10px 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      input {
        background-color: @white;
      }

      div:last-child {
        margin-right: 0;
      }

      span {
        float: left;
        margin-top: 5px;

        a {
          color: @blue;
        }
      }
    }

    .autocomplete {
      .list-search {
        li.item {
          padding: 0px;
          margin-bottom: 0px;
          background: none;

          .cover img {
            width: 100%;
          }
          .info {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .article-info-container {
    width: 100%;

    .image {
      float: left;
      margin-right: 10px;

      img {
        height: 60px;
      }
    }

    .info {
      float: left;
      margin-right: 10px;
      width: calc(~"100% - 170px");

      .title {
        font-size: @font-size-s;
        color: @black;
      }
    }

    .form-item {
      float: left;
      margin-bottom: 0;
    }
  }

  .admin-sort-tracks {
    span {
        font-size: @font-size-m;
        color: #ec1b2f;
        cursor: pointer;

        &.newest {
            margin-left: 10px;
        }

        &.active {
            font-weight: bold;
            cursor: default;
        }
    }
  }

  .admin-filter-tracks {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;

    .radio-container {
        margin: 0 10px 10px;

        label {
            line-height: 1.2;
        }
    }
  }

  .admin-tracks-pagination-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    a {
      font-family: @regular;
      text-transform: uppercase;
      font-size: @font-size-xl;
      font-weight: bold;
      text-align: center;
      width: fit-content;

      &:hover {
        text-decoration: none;
      }
    }

    .page-counter {
      font-family: @regular;
      font-size: @font-size-xl;
      text-align: center;
    }

    .fixed-width {
        width: 100px;
    }
  }

  /*.form-box {
    .clearfix();
    border: 2px solid @grey-light;
    padding: 10px;
    margin-bottom: 10px;

    .form-item:last-child {
      margin-bottom: 0;
    }
  }*/
}

.admin-interface {
  clear: both;

  label {
    text-align: left;
  }

  @media @mobile {
    margin-top: 20px;
  }
}

.admin-action-btn {
  clear: both;

  button {
    margin-left: 20px;

    &:before {
      padding-right: 10px;
    }
  }
}

button.btn-settings {
  margin: 20px 0 0;

  .lang-no & {
    background-color: @nrk-blue;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      padding: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      .nrk-icon-settings();
    }
  }

  &:before {
    padding: 0;
  }

  &.red-art-settings {
    &:before {
      padding-right: 10px;
      @media @mobile {
        padding-right: 0px;
      }
    }

    @media @mobile {
      span {
        display: none;
      }
    }
  }
}

button.btn-delete {
  background-color: @orange;
  margin: 20px 0 0;

  .lang-no & {
    background-color: @nrk-red;
    color: @white;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      padding: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      .nrk-icon-delete();
    }
  }

  &:before {
    padding: 0;
  }
}

.profile-edit {
  .col-two {
    h2 {
      margin-bottom: 10px;
    }
  }
  .blue-box {
    background-color: @grey-dark;
    padding: 10px;
  }
}

button.dr-icon-add {
  .lang-no & {
    background-color: @nrk-blue-dark;
    color: @white;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      padding: 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      .nrk-icon-plus("white");
    }

    &.btn-icon-label {
      background-color: @nrk-yellow;
      color: @nrk-black;

      &::before {
        .nrk-icon-plus("black");
      }
    }
  }
}

button.btn-icon-label {
  .lang-no & {
    &::before {
      margin-right: 10px;
    }
  }
}

a.admin-link {
  display: inline-block;
  text-decoration: underline;
  color: @blue;
  font-family: @regular;

  &.edit-link {
    margin-top: 0px;
    height: 40px;
    line-height: 40px;
    float: right;
  }
}


.lang-no {
    .admin {
        .playlist-sort-tracks {
            display: flex;
            margin: 15px 0;

            .radio-container {
                margin: 0 10px;
            }
        }
    }
}
