// Track

.track-info-right-long {

  .cover {
    max-width: 70px;
    float: left;
    margin-right: 10px;
  }

  .info-container {
    width: calc(~"60% - 80px");
    float: left;

    .track-embed & {
      width: calc(~"100% - 80px");
    }

    @media @mobile {
      width: calc(~"100% - 80px");
    }
  }

  .info {
    width: 60%;
    min-height: 70px;
    float: left;
    padding: 15px 0;
    position: relative;

    .track-embed & {
      width: 45%;
    }

    @media @mobile {
      width: 45%;
    }
  }

  .actions{
    display: flex;
    align-items: center;
    width: calc(~"40% - 5px");
    float: left;
    margin: 15px 0 14px 5px;

    .track-embed & {
      width: calc(~"55% - 5px");
    }

    @media @mobile {
      width: calc(~"55% - 5px");
    }
  }

  .recommendation {
    width: 40%;
    float: left;

    @media @mobile {
      width: 100%;
      margin: 10px 0 0;
    }
  }

  &.track-admin {
    margin-top: 10px;

    .cover {
      max-width: 60px;
      float: left;
      margin-right: 10px;
    }

    .info-container {
      width: calc(~"50% - 70px");
      float: left;
    }

    .info {
      width: 100%;
      min-height: 60px;
      padding: 10px 0;
      position: relative;
    }

    .recommendation {
      width: calc(~"50% - 50px");
      float: left;
      margin: 10px 0 0;
    }
  }
}

.track-embed {
  padding: 30px 0 2px;
  position: relative;
  margin: 10px 0 20px;

  &:before {
    content: "";
    background: url(../images/kk-logo-white.svg) 10px center no-repeat;
    background-size: 120px 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: @grey-dark;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    .gradient(left);
  }
}

.lang-no {
  .track-embed {
    &:before {
      content: "URØRT";
      background-image: url(../images/nrk/nrk-p3-logo.svg);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 30px;
      .nrk-fonts(@style:book);
      font-size: 20px;
      line-height: 30px;
      color: @white;
      height: 30px;
      width: calc(~"100% - 35px");
      padding: 0 0 0 35px;
      background-color: @nrk-black;
    }

    .track,
    &.track {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:after {
      background: @nrk-red;
    }
  }
}
