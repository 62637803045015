.nrk-icon-play(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-play-black.svg');
}
.nrk-icon-play(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-play-red.svg');
}
.nrk-icon-play(@color) when (@color = "white") {
  background-image: url('../images/nrk/nrk_icon-play-white.svg');
}

.nrk-icon-pause(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-pause-black.svg');
}
.nrk-icon-pause(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-pause-red.svg');
}
.nrk-icon-pause(@color) when (@color = "white") {
  background-image: url('../images/nrk/nrk_icon-pause-white.svg');
}

.nrk-icon-skip(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-skip-black.svg');
}
.nrk-icon-skip(@color) when (@color = "white") {
  background-image: url('../images/nrk/nrk_icon-skip-white.svg');
}

.nrk-icon-loader(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-loader-black.svg');
}
.nrk-icon-loader(@color) when (@color = "white") {
  background-image: url('../images/nrk/nrk_icon-loader-white.svg');
}

.nrk-icon-heart(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-heart-black.svg');
}
.nrk-icon-heart(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-heart-red.svg');
}

.nrk-icon-more(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-more-black.svg');
}
.nrk-icon-more(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-more-red.svg');
}

.nrk-icon-search() {
  background-image: url('../images/nrk/nrk_icon-search-black.svg');
}

.nrk-icon-profile() {
  background-image: url('../images/nrk/nrk_icon-profile-black.svg');
}

.nrk-icon-login() {
  background-image: url('../images/nrk/nrk_icon-login-black.svg');
}

.nrk-icon-plus(@color) when (@color = "black") {
  background-image: url('../images/nrk/nrk_icon-plus-black.svg');
}
.nrk-icon-plus(@color) when (@color = "yellow") {
  background-image: url('../images/nrk/nrk_icon-plus-yellow.svg');
}
.nrk-icon-plus(@color) when (@color = "white") {
  background-image: url('../images/nrk/nrk_icon-plus-white.svg');
}

.nrk-icon-link() {
  background-image: url('../images/nrk/nrk_icon-link-blue.svg');
}

.nrk-icon-facebook(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-fb-blue.svg');
}
.nrk-icon-facebook(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-fb-red.svg');
}

.nrk-icon-twitter(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-tw-blue.svg');
}
.nrk-icon-twitter(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-tw-red.svg');
}

.nrk-icon-soundcloud(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-sc-blue.svg');
}
.nrk-icon-soundcloud(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-sc-red.svg');
}

.nrk-icon-instagram(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-in-blue.svg');
}
.nrk-icon-instagram(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-in-red.svg');
}

.nrk-icon-spotify(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-sp-blue.svg');
}
.nrk-icon-spotify(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-sp-red.svg');
}

.nrk-icon-tiktok(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-tt-blue.svg');
}
.nrk-icon-tiktok(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-tt-red.svg');
}

.nrk-icon-tidal(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-ti-blue.svg');
}
.nrk-icon-tidal(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-ti-red.svg');
}

.nrk-icon-web(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-web-blue.svg');
}
.nrk-icon-web(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-web-red.svg');
}

.nrk-icon-mail(@color) when (@color = "blue") {
  background-image: url('../images/nrk/nrk_icon-mail-blue.svg');
}
.nrk-icon-mail(@color) when (@color = "red") {
  background-image: url('../images/nrk/nrk_icon-mail-red.svg');
}

.nrk-icon-delete() {
  background-image: url('../images/nrk/nrk_icon-delete-white.svg');
}

.nrk-icon-settings() {
  background-image: url('../images/nrk/nrk_icon-settings-white.svg');
}

// .nrk-icon-play(@color) {
//   background-image: url('data:image/svg+xml;utf8,<svg>');
// }
