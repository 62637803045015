.profile {

  &.login {

    h1 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .info-box {
      background: @grey-light;
      margin-bottom: 20px;
      padding: 20px;

      h2 {
        margin: -20px -20px 20px;
        border-bottom: 1px solid @white;
        padding: 20px;
        font-size: @font-size-m;
        text-transform: uppercase;
      }

      ul {

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .remember-me {
      .column(5);
      input[type="checkbox"] {
        width: auto;
        height: auto;
        float: left;
        margin-right: 5px;
      }
    }
  }

  .social-login a {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 1;
    padding: 10px 10px 10px 45px;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    color: #FFF !important;

    &:hover {
      opacity: 0.9;
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 34px;
      border-right: 1px solid #FFF;
      top: 0;
      left: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: 5px 5px;
      background-size: 24px;
    }

    &.social-login-twitter {
      background-color: @twitter;

      &:before {
        background-image: url(../images/twitter.svg);
      }
    }

    &.social-login-facebook {
      background-color: @facebook;

      &:before {
        background-image: url(../images/facebook.svg);
      }
    }

    &.social-login-google {
      background-color: @google;

      &:before {
        background-image: url(../images/google-plus.svg);
      }
    }

    &.social-login-soundcloud {
      background-color: @soundcloud;

      &:before {
        background-image: url(../images/soundcloud.svg);
      }
    }
  }

}
