// Overlay
.overlay {
  display: none;
  height: 100%;
  width: 100%;
  background-color: @black;
  position: fixed;
  left: 0;
  top:0;
  opacity: 0.8;
  z-index: 1000;
}

// Top search
.search-top {
  box-shadow: 0px 1px 8px 0px rgba(50, 50, 50, 0.8);
  display: none;
  position: absolute;
  top: 40px;
  z-index: 1100;
  left: 40px;
  right: 40px;

  @media @mobile {
    left: 10px;
    right: 10px;
    top: 20px;
    text-align: left;
  }

  &.active {
    display: block;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// Search bar
.search-bar {
  position: relative;
  z-index: 200;

  input[type="text"] {
    background-color: @grey-light;
    width: 100%;
    color: @black;
    padding: 20px 20px 20px 80px;
    font-size: @font-size-xl;
    outline: none;
    border: none;
    text-overflow: ellipsis;

    @media @mobile {
      padding:10px 20px 10px 60px;
      font-size: @font-size-m;
    }
  }

  .icon-search {
    color: @black;
    position: absolute;
    top: calc(~'(50% - 16px)');
    left: 20px;

    @media @mobile {
      top: calc(~'(50% - 8px)');

      &:before {
        font-size: @font-size-m;
        line-height: 16px;
      }
    }

    .lang-no & {
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        padding: 0 0 0 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        .nrk-icon-search();
      }
      @media @mobile {
        top: calc(~'(50% - 16px)');
      }
    }
  }

  .search-top & {

    input[type="text"] {
      height: 80px;
    }
  }
}

// Search autocomplete
.search-autocomplete {
  display: none;
  width: 100%;
  background-color: @grey-light;
  padding: 20px;
  border-top: solid 2px @white;

  > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.active {
    display: block;
  }

  .list-search {
    max-height: 230px;
    overflow-y: auto;

    .item {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .search-title {
    .h3;
    color: @black;
  }
}

// small autocomplete
.autocomplete-small {
  position: relative;
  width: 40%;

  .search-bar {
    position: relative;
    z-index: 1;

    input[type="text"] {
      padding: 0 10px 0 40px;
      line-height: 40px;
      font-size: @font-size-s;
    }

    .icon-search {
      top: calc(~'(50% - 8px)');
      left: 10px;


      .lang-no & {
        &::before {
          content: '';
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .autocomplete {
    box-shadow: 0px 1px 8px 0px rgba(50, 50, 50, 0.8);
    margin-top: 10px;
    padding: 10px;
    background-color: @grey-light;
    width: 100%;
    position: absolute;
    z-index: 100;
    display: none; // Fix

    &.active {
      display: block;
      overflow-y: auto;
      max-height: 500px;
    }

    .info {
      float: left;
    }

    .status {
      float: right;
      background-color: @grey-dark;
      color: @white;
      line-height: 20px;
      padding: 0 5px;
      margin-top: 10px;
      margin-right: 10px;
      font-size: @font-size-xs;
    }
  }

  &.playlist-search {
    margin-bottom: 20px;
  }

  .search-title {
    .h3;
    color: @black;

    button.btn-delete {
      margin-top: 0px;
    }
  }
}
