.threecol {
  .col-one {
    .column(4);

    @media @mobile {
      width: 100%;
    }
  }

  .col-two {
    .column(6);

    @media @mobile {
      width: 100%;
    }
  }

  .col-three {
    .column(2);
    margin-right: 0;

    @media @mobile {
      width: 100%;
    }
  }
}
