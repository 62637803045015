// Track on article
.track-overlay {
  background: fade(@grey-dark, 80);
  position: absolute;
  bottom: 10px;
  left: 10px;

  @media @mobile {
    right: 10px;
  }

  .track,
  .label {
    color: @white;
    text-wrap: nowrap;
  }

  .artist,
  .title {
    display: none;
  }

  .label {
    font-size: @font-size-xs;
    font-family: @bold;
    text-transform: uppercase;
  }

  .info {
    display: inline-block;
    margin: 5px 10px 5px 60px;
  }

  .cover.small {
    position: absolute;
    top: 0;
    left: 50px;

    img {
      height: 40px;
      width: 40px;
    }
  }
  .actions {
    margin-top: 0;
    height: 40px;

    .btn-play {
      width: 100%;
      position: relative;
      padding-left: 40px;
      cursor: pointer;

      &:before {
        color: @white;
        border: 2px solid @white;
        border-radius: 50%;
        line-height: 26px;
        width: 26px;
        position: absolute;
        top: 5px;
        left: 10px;
        text-align: center;
        font-size: 2.2rem;
      }
    }
  }
}
.image,
figure {
  .recomended {
    padding: 3px 6px 1px;
    background-color: @pink;
    color: @white;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.lang-no {
  .track-overlay {
    &.track,
    .track {
      border-bottom: none;
      padding: 0;
      .nrk-fonts(@style:bookitalic);
      line-height: 15px;

      .info {
        padding: 0;
        margin: 5px 10px 5px 60px;
      }

      .label {
        .nrk-fonts(@style:heavyitalic);
        line-height: 15px;
      }
    }

    .actions {
      .btn-play:before {
        content: '';
        width: 28px;
        height: 28px;
        .nrk-icon-play("white");
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;
        border-radius: 0;
        border-width: 1px;
      }
    }

    &.now-playing  {
      .actions .btn-play:before {
        .nrk-icon-pause("white");
      }
    }
  }
  .image,
  figure {
    .recomended {
      background-color: @nrk-red;
      .nrk-fonts(@style:semibolditalic);
    }
  }
}
