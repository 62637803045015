.battle-container {
  background-color: @grey-dark;
  padding: 40px;
  position: relative;

  @media @mobile {
    padding: 10px;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    height: 20px;
    width: 100vw;
    left: calc(~"(100vw - 940px) / 2 * -1");
    z-index: -1;
    top: -20px;

    @media @content {
      left: -10px;
    }
  }

  &:before {
    .gradient(left);

    .lang-no & {
      background-color: @nrk-blue;
    }
  }

  &:after {
    top:0;
    height: 100%;
    background-color: @grey-dark;

    .lang-no & {
      background-color: @black;
    }
  }

  @media @mobile {
    &.active-battle {
      .battle-section {
        padding-bottom: 80px;
      }
    }

    .flex-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .battle-section {
        flex-grow: 1;
        align-items: stretch;

        .active-battle & {
          padding-bottom: 80px;
        }
      }
    }
  }

  .battle-section {
    .column(4);
    position: relative;

    @media @mobile {
      .column(6);
    }

    .h1 {
      font-size: 3.2rem;
      font-family: @bold;
      font-weight: normal;
      color: @white;
      margin-bottom: 40px;
      width: 100%;

      @media @mobile {
        font-size: 2.6rem;
        margin-top: 50px;
        margin-bottom: 10px;
        width: 66%;
      }
      .lang-no & {
        .nrk-fonts(@style:heavyitalic);
      }
    }

    .track {
      .column(8);
      margin-right: 10px;
      margin-left: 10px;

      @media @mobile {
        margin-right: 0;
        margin-left: 0;
      }

      .cover {
        width: 100%;
        margin-bottom: 10px;

        img {
          width: 100%;
        }
        @media @mobile {
          float: none;
        }
      }

      .info {

        .artist, .title {
          white-space: inherit;
          overflow: inherit;
          text-overflow: inherit;
        }

        .artist a {
          color: @white;
        }
        .title a {
          color: @white;
          word-break: break-word;
        }

        @media @mobile {
          .artist a {
            color: @white;
          }
          .title a {
            color: @white;
          }
        }
      }
    }

    .like-baro {
      float: left;
    }

    .vote-btn {
      .transition(transform 100ms ease-in-out);
      position: absolute;
      top: 120px;
      color: @blue;
      border: 2px solid @blue;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 67px;
      font-size: 3rem;
      cursor: pointer;

      @media @mobile {
        top: inherit;
        bottom: 0;
      }

      &:before {
        content: @fa-var-thumbs-o-up;
      }

      &.active {
        color: @white;
        background-color: @blue;
        line-height: 58px;

        .lang-no & {
          background-color: @nrk-red;
        }

        &:before {
          content: @fa-var-thumbs-up;
        }
      }

      &:active {
        .transform(scale(1.2));
      }
    }

    &.track-left {

      .vote-btn {
        right: -70px;

        @media @mobile {
          right: inherit;
          left: 10px;
        }
      }

    }

    &.track-right {
      margin-right: 0;

      .track,
      .like-baro,
      .like-baro-num,
      .flag {
        float: right;
      }
      .vote-btn {
        left: -70px;

        @media @mobile {
          left: inherit;
          right: 10px;
        }
      }

      .h1 {
        text-align: right;

        @media @mobile {
          float: right;
        }
      }

    }

    &.versus-middel {
      color: @white;
      position: relative;
      text-align: center;
      pointer-events: none;

      @media @mobile {
        .column(12);
        position: absolute;
        top: 10px;
        left: 0;
      }

      &:after {
        content: "VS";
        font-size: @font-size-xxl;
        font-family: @bold;
        font-weight: normal;
        color: @white;
        background-color: @orange;
        border-radius: 50%;
        line-height: 80px;
        width: 80px;
        margin-top: 118px;
        text-align: center;
        display: inline-block;

        .lang-no & {
          background-color: @nrk-red;
          .nrk-fonts(@style:heavyitalic);
        }

        @media @mobile {
          margin-top: 50px;
          font-size: 2.6rem;
          line-height: 65px;
          width: 65px;
        }
      }
      &.active-battle {

        &:after {
          margin-top: 100px;

          @media @mobile {
            margin-top: 32px;
          }
        }
      }

      .battle-section-info {
        position: absolute;
        left: 0;
        bottom: -75px;
        pointer-events: auto;

        a {
          color: @white;

          span {
            text-decoration: underline;
          }
        }

        @media @mobile {
          display: none;
        }
      }
    }
  }

  .battle-section-info.phone {
    display: none;

    a {
      color: @white;

      span {
        text-decoration: underline;
      }
    }

    @media @mobile {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 30px;
      float: left;
    }
  }

  .battle-week {
    clear: both;
    width: 100%;
    text-align: center;
    color: @white;
    font-size: @font-size-xl;
    font-family: @bold;
    padding-top: 40px;

    .week-number {
      text-transform: uppercase;
      display: inline-block;
    }

    [class*="dr-icon"] {
      font-size: 2.5rem;
      color: @blue;

      &.disable {
        color: @grey
      }
    }
    .lang-no & {
      .nrk-fonts(@style:heavyitalic);
    }
  }

  // Like barometer for battles.
  .like-baro {
    @bg-color: @grey-dark;
    width: 25px;
    height: 127px;
    position: relative;
    overflow: hidden;
    margin: 0 15px 5px;

    @media @content {
    margin: 0 10px 5px;
    }

    @media @mobile {
      height: 80px;
    }

    .fill {
      right: 3px;
      position: absolute;
      bottom: 5px;
      left: 3px;
      top: 2px;
      background: repeating-linear-gradient(
        @bg-color,
        @bg-color 3px,
        @orange 3px,
        @orange 12px
      );

      .lang-no & {
        background: repeating-linear-gradient(
          @bg-color,
          @bg-color 3px,
          @nrk-red 3px,
          @nrk-red 12px
        );
      }

      @media @mobile {
        background: repeating-linear-gradient(
          @bg-color,
          @bg-color 3px,
          @orange 3px,
          @orange 9px
        );

        .lang-no & {
          background: repeating-linear-gradient(
            @bg-color,
            @bg-color 3px,
            @nrk-red 3px,
            @nrk-red 9px
          );
        }
      }
    }

    .percentage {
      position: absolute;
      height: 100%;
      width: 100%;
      background: @bg-color;
      bottom: 0;
      left: 0;

      &:after {
        content: "";
        height: 20px;
        width: 100%;
        position: absolute;
        bottom: -20px;
        background: -moz-linear-gradient(top,  @bg-color 0%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(top,  @bg-color 0%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to bottom,  @bg-color 0%,rgba(125,185,232,0) 100%);
      }
    }
  }

  .like-baro-num {
    color: @white;
    font-size: @font-size-m;
    font-family: @bold;
    display: inline-block;
    margin: 0 5px;
    float: left;
    width: 45px;
    text-align: center;

    @media @content {
      margin: 0;
    }

    @media @mobile {
      font-size: @font-size-xs;
    }

    .lang-no & {
      .nrk-fonts(@style:heavyitalic);
    }
  }

  .flag {
    width: 25px;
    height: 18px;
    display: inline-block;
    background-size: cover;
    margin: 5px 15px;
    float: left;

    @media @content {
      margin: 5px 10px;
    }

    &.dk{
      background-image: url("../images/dk.svg");
    }

    &.no {
      background-image: url("../images/no.svg");
    }
  }
}
