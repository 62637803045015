.nrk-fonts(@style) when (@style = heavyitalic) {
  font-family: @nrk-display;
  font-weight: 900;  
  font-style: italic;
  line-height: 1.5;
}
.nrk-fonts(@style) when (@style = bold) {
  font-family: @nrk-normal;
  font-weight: 800;  
  font-style: normal;
  line-height: 1.5;
}
.nrk-fonts(@style) when (@style = bolditalic) {
  font-family: @nrk-normal;
  font-weight: 800;  
  font-style: italic;
  line-height: 1.5;
}
.nrk-fonts(@style) when (@style = semibolditalic) {
  font-family: @nrk-normal;
  font-weight: 600;  
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.22em;
}
.nrk-fonts(@style) when (@style = book) {
  font-family: @nrk-normal;
  font-weight: 300;  
  font-style: italic;
  line-height: 1.5;
}
.nrk-fonts(@style) when (@style = bookitalic) {
  font-family: @nrk-normal;
  font-weight: 300;  
  font-style: italic;
  line-height: 1.5;
}
.nrk-fonts(@style) when (@style = light) {
  font-family: @nrk-normal;
  font-weight: 200;  
  font-style: normal;
  line-height: 1.5;
}

.kanon-font-fam(@style) when (@style = bold){
  font-family: @bold;
  font-style: normal;
  font-weight: 700;
}
.kanon-font-fam(@style) when (@style = semibold){
  font-family: @semibold;
  font-style: normal;
  font-weight: 600;
}
.kanon-font-fam(@style) when (@style = regular){
  font-family: @regular;
  font-style: normal;
  font-weight: 400;
}
.kanon-font-fam(@style) when (@style = light){
  font-family: @light;
  font-style: normal;
  font-weight: 300;
}