// TODO: Fix object fit stuff when we have images

.list-news {
  a {
    &:hover {
      text-decoration: none;

      h2 {
        text-decoration: underline;
      }
    }
  }


  li {
    background: @dot-border;
    padding-bottom: 14px;
    margin-bottom: 20px;

    @media @mobile {
      background: none;
      float: left;
      margin: 0;
      padding: 0;
    }

    .image {
      position: relative;

      @media @mobile {
        min-height: 165px;
        background-color: @grey-light;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .text {
      h2 {
        margin-top: 7px;
        font-size: 4.2rem;
        line-height: 4.2rem;

        @media @mobile {
          font-size: @font-size-m;
          line-height: @font-size-m;
        }
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  @media @desktop {
    li:not(:first-child) {
      overflow: hidden;

      .image {
        float: left;

        img  {
          width: 140px;
        }
      }

      .text {

        h2 {
          display: block;
          margin-left: 160px;
          margin-top: 4px;
          font-size: 3rem;
          line-height: 3rem;
        }

        p {
          display: none;
        }
      }

      .recomended,
      .track-overlay {
        display: none;
      }
    }
  }
}

.lang-no {
  .list-news {
    li {
      background: none;
    }
  }
}