* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  font-size:62.5%;
}

body {
  font-size: @font-size-s;
  font-family: @regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: @black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
  overflow-x: hidden;

  &.waiting * {
    cursor: progress;
  }

  &.lang-no {
    .nrk-fonts(@style:book);
  }
}

img {
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;

  &.draggable-list,
  &.playlist-list {
    margin-top: 20px;

    li {
      cursor: move;
    }
  }

  li {
    list-style: none;

    &.drop-target {
      position: relative;
      min-height: 80px;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
      line-height: 80px;
      font-size: @font-size-l;
      background: @grey-light;

      &:after {
        background: @dot-border;
        content:"";
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 1px;
        position: absolute;
      }
    }
  }
  &.list-artistpage-tracks {
    li {
      list-style: none;
      cursor: move;

      &.drop-target {
        background: @grey;

        &:after {
          background: none;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: @black;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.hidden {
  display: none;
}

i.fa {
  font-size: 24px;
}
