.recommendation {
  .clearfix;
  width: 100%;
  float: left;
  color: #656565;
  margin-top: 20px;
  position: relative;

  .picture {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
    float: left;
    margin-right: 10px;
  }

  .info {
    .transform(translateY(-50%));
    position: absolute;
    top: 50%;
    left: 50px;
  }

  .text {
    font-size: @font-size-xs;
    font-style: italic;
    font-family: @regular;
    margin-bottom: 2px;
    color: @orange;
  }

  .reviewer {
    font-size: @font-size-xs;
    font-family: @regular;
    text-transform: uppercase;
  }
}

.lang-no {
  .recommendation {
    .text {
      color: @nrk-black;
      .nrk-fonts(@style:bookitalic);
    }
    
    .reviewer {
      color: @nrk-red;
      .nrk-fonts(@style:bookitalic);
    }
  }
}
