.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
}

// FADEOUT EFFECT FOR ALERT MES
.fadeOut (@fadeOut) {
  -webkit-animation: fadeOut 1000ms linear 5s forwards;
  -moz-animation:    fadeOut 1000ms linear 5s forwards;
  -o-animation:      fadeOut 1000ms linear 5s forwards;
  animation:         fadeOut 1000ms linear 5s forwards;
}

@-webkit-keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

// SPIN EFFECT FOR loader

@-webkit-keyframes spin {
  from  { -webkit-transform:rotate(0deg); }
  to    { -webkit-transform:rotate(360deg); }
}
@-moz-keyframes spin {
  from  { -moz-transform:rotate(0deg); }
  to    { -moz-transform:rotate(360deg); }
}
@-o-keyframes spin {
  from  { -o-transform:rotate(0deg); }
  to    { -o-transform:rotate(360deg); }
}
@keyframes spin {
  from  { transform:rotate(0deg); }
  to    { transform:rotate(360deg); }
}
