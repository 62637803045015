.actions {
  margin-top: 15px;

  .btn {
    width: 33.33%;
    text-align: center;
    border-right: 1px solid @grey-light;
    position: relative;
    margin: -2px;
    cursor: pointer;
    font-size: @font-size-m;

    .text {
      font-size: 0.9rem;
      color: @grey;
      font-family: @regular;
      text-transform: uppercase;
      margin-top: 5px;
      right: 0;
      text-align: center;
      width: 100%;

      .lang-no & {
        .nrk-fonts(@style:bookitalic);
      }
    }

    &:before {
      padding: 2px;
    }

    &:hover {
      &:before {
        color: @blue;

        .lang-no & {
          color: @nrk-blue;
        }
      }
    }

    &[class*="dr-icon"][class*="-boxed"] {
      background-color: transparent;
    }

    &[class*="-boxed"] {
      &:before {
        background-color: @black;
        border-radius: 0;
      }

      &.btn-play:before {
        border-radius: 50%;
      }

      &:hover {
        &:before {
          background-color: @blue;
          color: @white;

          .lang-no & {
            background-color: @nrk-blue;
          }
        }
      }

      .now-playing & {
        &:before {
          content: "\25ae";
          background-color: @blue;
        }
      }
    }

    &:last-child {
      border-right: none;
    }

    &.btn-share {
      position: relative;

      .social-share {
        display: none;
        position: absolute;
        right: 0;
        top: 40px;
        text-align: right;
        background-color: @blue;
        font-size: @font-size-s;
        z-index: 10;
        box-shadow: 0px 4px 8px 0px rgba(50, 50, 50, 0.3);

        .share-link {
          padding: 5px 11px;
          display: inline-block;
          width: 150px;
          color: @white;

          &:after {
            .fa-icon();
            padding-left: 5px;
            width: 15px;
            text-align: center;
          }

          &.facebook:after {
            content: @fa-var-facebook;
          }

          &.twitter:after {
            content: @fa-var-twitter;
          }
          &.embed:after {
            content: @fa-var-code;
          }
          &.download:after {
            content: @fa-var-download;
          }
          &.mail:after {
            content: @fa-var-envelope;
          }
          &.artist-delete:after {
            content: @fa-var-trash-o;
          }
        }
      }

      &:hover {
        .social-share {
          display: block;
        }
      }
    }

    &.btn-favorite {
      &:before {
        .transition(transform 100ms ease-in-out);
      }

      &:hover {
        &:before {
          color: @pink;

          .lang-no & {
            color: @nrk-red;
          }
        }
      }

      &.favorite {
        &:before {
          color: @pink;

          .lang-no & {
            color: @nrk-red;
          }
        }

        &:hover {
          &:before {
            opacity: 0.5;
          }
        }
      }

      &:active {
        &:before {
          .transform(scale(1.2));
        }
      }
    }

    &.btn-facebook {
      &:before {
        background-image: url(../images/facebook.svg);
        background-color: @facebook;
        color: @white;
        content: "";
        height: 30px;
        width: 30px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover {
        &:before {
          background-color: @facebook;
          opacity: 0.95;
        }
      }
    }

    &.btn-soundcloud {
      &:before {
        content: " ";
        background-image: url(../images/soundcloud.svg);
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center 5px;
        background-color: @soundcloud;
        position: relative;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }

      &:hover {
        &:before {
          background-color: @soundcloud;
          opacity: 0.8;
        }
      }
    }

    &.btn-web {
      &:hover {
        &:before {
          background-color: @black;
        }
      }
    }
  }
}

.lang-no {
  .actions {
    .btn {
      border-right: none;
      margin-bottom: 10px;

      .text {
        .nrk-fonts(@style:bookitalic);
      }

      &:hover {
        &:before {
          color: @nrk-blue;
        }
      }

      &.btn-play {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-play("black");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-play("red");
          }
        }
      }

      &.btn-favorite {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-heart("black");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.favorite,
        &:hover {
          &::before {
            .nrk-icon-heart("red");
          }
        }
      }

      &.btn-share {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-more("black");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:hover {
          &::before {
            .nrk-icon-more("red");
          }
        }
        .social-share {
          background-color: @nrk-red;
          .nrk-fonts(@style:bookitalic);
        }
      }

      &.btn-facebook {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-facebook("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-facebook("red");
          }
        }
      }

      &.btn-instagram {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-instagram("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-instagram("red");
          }
        }
      }

      &.btn-spotify {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-spotify("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-spotify("red");
          }
        }
      }

      &.btn-tiktok {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-tiktok("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-tiktok("red");
          }
        }
      }

      &.btn-tidal {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-tidal("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-tidal("red");
          }
        }
      }

      &.btn-twitter {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-twitter("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-twitter("red");
          }
        }
      }

      &.btn-soundcloud {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-soundcloud("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-soundcloud("red");
          }
        }
      }

      &.btn-web {
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          .nrk-icon-web("blue");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border-radius: 0;
        }
        &:hover {
          &::before {
            background-color: transparent;
            .nrk-icon-web("red");
          }
        }
      }
    }
  }
  .track.now-playing {
    .actions .btn.btn-play {
      &::before {
        .nrk-icon-pause("black");
      }

      &:hover::before {
        .nrk-icon-pause("red");
      }
    }
  }
}
