.list-track-container {
  .clearfix;
  margin-bottom: 20px;

  @media @mobile {
    margin-bottom: 10px;
  }

  .show-more-link {
    margin-top: 20px;
  }
}

.list-track {
  .clearfix();

  @media @mobile {
    width: 100%;
  }

  &.vertical {
    //.column(4);

    .item {
      .clearfix();
      background: @dot-border;
      padding-bottom: 20px;
      margin-bottom: 20px;

      @media @mobile {
        margin-bottom: 10px;
      }
    }
  }

  &.vertical-long {

    .item {
      .clearfix();
      background: @dot-border;
      padding-bottom: 20px;
      margin-bottom: 20px;

      @media @mobile {
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.horizontal {
    width: 100%;
    clear: both;

    .item {
      .column(3);
      .omega(4);
      margin-bottom: 20px;

      @media @mobile {
        .column(12);
        .omega(0);
        padding-bottom: 10px;
        margin-bottom: 10px;
        background: @dot-border;
      }
    }

    .track-container {
      position: relative;
    }
  }
}

.lang-no {
  .list-track {
    &.vertical {
      .item {
        background: none;
      }
    }

    &.vertical-long {
      .item {
        padding: 0;
        background: none;

        .track {
          padding: 0 0 20px;
        }
      }
    }

    &.horizontal {
      .item {
        @media @mobile {
          background: none;
        }
      }
    }
  }
}
