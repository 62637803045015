.article-container {
	.article-slider {
		width: 100%;
		margin: 0 auto;

		.slide--viewer {
			height: auto;
			overflow: hidden;
			position: relative;

			.slide---group {
				width: 100%;
				height: auto;
				position: relative;

				.slide {
					width: 100%;
					height: auto;
					float: left;
					display: none;
			    -webkit-animation-name: fade;
			    -webkit-animation-duration: 2s;
			    animation-name: fade;
			    animation-duration: 2s;

				  @-webkit-keyframes fade {
				    from {opacity: .4}
				    to {opacity: 1}
				  }

				  @keyframes fade {
				    from {opacity: .4}
				    to {opacity: 1}
				  }

					> img {
						width: 100%;
						height: auto;
						object-fit: cover;
					}

					&.active-slide {
						display: block;
					}
				}
			}
		}
	}

	.article-slide-footer {
		width: 100%;
		display: inline-block;
		clear: both;
		background: @dot-border;
		padding-bottom: 1.0rem;

		.slide-caption {
			width: 70%;
			float: left;
			padding-right: 2.0rem;
			background: none;
			font-size: 1.4rem;
			color: @grey;
			margin-top: 1.0rem;
		}

		.count {
			width: 30%;
			float: right;
			margin-right: 0px;
			margin-top: 1.0rem;
			text-align: right;

			a.button {
			text-decoration: none;
			color: @grey;
			cursor: pointer;
			font-size: 2.1rem;

				&:hover {
					color: @black;
				}
			}
			span {
				font-size: 1.6rem;
			}
		}
	}	
}
