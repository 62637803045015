.submit-btn(@color:@blue) {

  .lang-no & {
    background-color:@nrk-yellow;
    color: @nrk-black;
    .nrk-fonts(@style:bolditalic);
  }

  margin-bottom: 10px;
  margin-left: 10px;
  width: auto;
  padding: 0 15px;
  line-height: 42px;
  height: 40px;
  color: @white;
  background-color: @color;
  font-size: @font-size-xs;
  font-family: @semibold;
  text-transform: uppercase;
  float: right;
  border: none;
  outline: none;

  &[class*="dr-icon"] {
    color: @white;
    font-size: @font-size-s;
    line-height: 21px;

    &:before {
      padding-right: 10px;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    .transform(scale(0.95));
  }

  &.cancel-btn {
    background-color: @grey;

    .lang-no & {
      background-color:@grey-light;
    }
  }

  &.delete-btn {
    background-color: @orange;

    .lang-no & {
      background-color:@nrk-red;
      color: @white;
    }
  }
}
