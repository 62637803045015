.popup-window {
    box-shadow: 0px 1px 8px 0px rgba(50, 50, 50, 0.8);
    background: @white;
    position: fixed;
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
    z-index: 3000;

    .message {
      padding: 20px 40px;
      text-align: center;
      position: relative;

      .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: @font-size-m;
        color: @grey-light;
        cursor: pointer;

        &:hover {
          color: @grey;
        }
      }

      h3 {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-family: @semibold;
        margin-bottom: 20px;
        color: @black;
        text-transform: inherit;
      }

      a.btn-link {
        .submit-btn(@blue);
        float: none;
        display: inline-block;
        margin: 0;
        min-width: 220px;

        .lang-no & {
          line-height: 42px;
        }
      }

      p {
        font-size: @font-size-m;
        line-height: @font-size-l;
        font-family: @regular;
        margin-bottom: 20px;

        a {
          color: @blue;
          text-transform: uppercase;
          text-decoration: none;
          display: inline-block;
        }
      }

      textarea {
        width: 440px;
        background-color: @grey-dark;
        color: @white;

        @media @mobile {
          width: 100%;
        }
      }
    }

    .sec-message {
      background: @grey-light;
    }
}
