#audio-player {
  position: fixed;
  bottom: -80px;
  width: 100%;
  height: 70px;
  background: @black;
  color: @white;
  z-index: 4000;
  border-top: 1px solid @grey;
  box-shadow: 0 0 20px @black;
  .transition(bottom .5s ease);

  &.active {
    bottom: 0;
  }

  @media @mobile {
    height: auto;
    min-height: 120px;
    bottom: -135px;
    padding-top: 10px;
  }

  .info {
    .column(2);
    padding: 15px 0;

    @media @mobile {
      padding: 5px 0;
    }

    .artist {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      a {
        color: @grey;
        font-size: @font-size-s;
        font-family: @semibold;
        line-height: 20px;

        .lang-no & {
          .nrk-fonts(@style:bolditalic);
        }
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      a {
        color: @white;
        font-size: @font-size-m;
        line-height: 20px;
      }
    }


    @media @mobile {
      // .column(3);
      width: 30%;
      padding-right: 10px;
      margin-right: 0px;
    }
  }

  .progress {
    .column(7.5);
    position: relative;
    height: 70px;

    .barUnderlay {
      display: block;
      height: 2px;
      width: 100%;
      padding: 10px 0;
      margin-top: 12px;
      cursor: pointer;

      .barBackground {
        display: block;
        height: 2px;
        width: 100%;
        background: @grey;
      }

      .barMarker {
        display: block;
        position: absolute;
        top: 18px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: @white;
        .transform(translateX(-50%));
      }
      .barCurrent {
        display: block;
        position: absolute;
        top: 22px;
        left: 0;
        height: 2px;
        background: @white;
        pointer-events: none;
      }
    }

    .time {
      display: inline-block;
      position: absolute;
      top: 36px;

      &.currentTime {
        left: 0;
      }

      &.duration {
        right: 0;
      }
    }

    @media @mobile {
      width: 100%;
      height: 30px;

      .barUnderlay {
        height: 6px;
        margin-top: 0;
        padding: 8px 0;

        .barBackground {
          height: 4px;
        }

        .barMarker {
          top: 0;
          width: 20px;
          height: 20px;
        }

        .barCurrent {
          top: 8px;
          height: 4px;
        }
      }
      .time {
        display: none;
      }
    }
  }

  .playing-actions {
    .column(2.5);
    margin-right: 0;
    float: right;
    text-align: right;

    button {
      float: none;
      display: inline-block;
      height: 40px;
      width: 40px;
      margin: 15px 5px;
      padding: 0;
      background-color: @black;
      border-radius: 50%;

      @media @mobile {
        margin: 5px 5px;
      }

      &[class*="dr-icon"]  {
        font-size: 3rem;
        line-height: 40px;
        vertical-align: middle;

        &:before {
          padding: 0;
        }
      }
      &[disabled] {
        pointer-events: none;
        opacity: 0.3;
        &:before {
          color: @grey;
        }
      }

      &#playpause {
        background-color: @grey-dark;
      }

      &#playprevious:before {
        .transform(rotate(180deg));
      }

      &.dr-icon-play.loader {
        font-size: 2.4rem;

        &:before {
          content: "\f112";
          -webkit-animation: spin 1s infinite linear;
          -moz-animation:    spin 1s infinite linear;
          -o-animation:      spin 1s infinite linear;
          animation:         spin 1s infinite linear;
        }
      }
    }

    @media @mobile {
      // .column(9);
      width: 70%;
      margin-right: 0;
      float: right;
    }
  }
}

.lang-no {
  #audio-player {
    .info {
      .artist {
        a {
          color: @white;
          .nrk-fonts(@style:bolditalic);
          text-transform: uppercase;
          font-size: @font-size-s;
        }
      }
  
      .title {
        color: @white;
        .nrk-fonts(@style:bookitalic);
        text-transform: uppercase;
        font-size: @font-size-s;
      }
    }

    .playing-actions {
  
      button {
        background-position: center;
        background-size: auto 24px;
        background-repeat: no-repeat;
        border-radius: 0;  

        &:before {
          content: '';
        }
  
        &#playpause {
          .nrk-icon-play("white");

          &.audio-playing {
            .nrk-icon-pause("white");
          }

          &.loader {
            .nrk-icon-loader("white");
            background-color: transparent;
            -webkit-animation: spin 1s infinite linear;
            -moz-animation:    spin 1s infinite linear;
            -o-animation:      spin 1s infinite linear;
            animation:         spin 1s infinite linear;

            &:before {
              content: "";
              -webkit-animation: none;
              -moz-animation:    none;
              -o-animation:      none;
              animation:         none;
            }
          }
        }
  
        &#playprevious,
        &#playnext {
          background-size: 18px auto;
          .nrk-icon-skip("white");
        }

        &#playprevious {
          transform: rotate(180deg);
        }
      }
    }
  }
}
