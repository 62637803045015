.list-playlist-container {
  .clearfix;
  margin-bottom: 40px;
  padding: 0 20px;
  background-color: @grey-dark;

  @media @mobile {
    padding: 0 10px;
  }

  h3 {
    font-size: 2.4rem;
    color: @orange;
    line-height: 2.6rem;
    margin-bottom: 20px;
    padding: 16px 0 11px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -20px;
      width: calc(~"100% + 40px");
      height: 1px;
      .gradient(left);
    }

    @media @mobile {
      font-size: @font-size-l;
      line-height: @font-size-xl;
      margin-bottom: 10px;
      padding: 13px 0 11px;

      &:after {
        left: -10px;
        width: calc(~"100% + 20px");
      }
    }
  }
}

.list-playlist {
  .clearfix();
  overflow: hidden;

  a {
    font-family: @semibold;
    color: @grey-light;

    @media @mobile {
      font-size: @font-size-s;
    }
  }

  .item {
    .column(2);
    .omega(6);
    margin-bottom: 20px;

    @media @mobile {
      .grid(12, 100%, 10px);
      .omega-reset(6);
      .column(6);
      .omega(2);
      margin-bottom: 10px;

    }
  }
}

.lang-no {
  .list-playlist-container {
    background-color: transparent;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100%;
      top: 0;
      left: calc(~"(100vw - 940px) / 2 * -1");
      background-color: @nrk-blue-dark;
      background-image: url(../images/nrk/nrk_bg-playlist-l.jpg);
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;

      @media @content {
        left: -10px;
      }

      @media @mobile {
        background-image: none;
      }
    }

    h3 {
      .nrk-fonts(@style:semibolditalic);
      font-size: 2.4rem;
      color: @white;
      line-height: 1;
      text-align: center;
      padding: 20px 0 10px;
  
      &:after {
        content: "";
        display: none;
        background: transparent;
      }
    }

    .show-more a {
      color: @white;
    }
  }
}
