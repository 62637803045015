.article-list {
	margin-bottom: 20px;
	
	ul {
		li.item {
			.column(12);
			padding: 20px 0px;
			background: @dot-border;
			background-size: 3px;

			&:last-child {
				background: none;
			}

			&:first-child {
				.info h2 {
					font-size: @font-size-xl;

					@media @mobile {
						font-size: @font-size-m;
					}
				}
			}
			
			.cover {
				img {
					.column(4);

					@media @desktop {
						width: 140px;
					}
					@media @mobile {
						margin-right: 10px;
					}
				}
			}

			.info {
        .column(8);
        margin: 0;
        padding: 0;
        font-size: @font-size-s;
        line-height: @font-size-m;
        font-family: @semibold;

        &:hover {
          text-decoration: underline;
        }

				h2 {
					font-size: @font-size-m;
					line-height: @font-size-xl;

					@media @mobile {
						font-size: @font-size-s;
						line-height: @font-size-m;
					}
				}

				span {
					color: @grey;
					font-size: @font-size-xs;
				}
			}
		}
	}
}

.lang-no {
	.article-list {		
		ul {
			li.item {	
				.info {	
					span {
						.nrk-fonts(@style:book);
					}
				}
			}
		}
	}
}