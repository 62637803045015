.profile {
  position: relative;

  @media @mobile {
    padding-top: 50px;
  }

  .profile-logout {
    width: auto;
    padding: 0 15px;
    line-height: 40px !important;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    font-family: "gibsonSemiBold", "Arial Black";
    text-transform: uppercase;
    z-index: 1;
  }
}

.profile-top,
.profile-edit {
  position: relative;
  .clearfix();

  .profile-picture {
    .column(3);

    img {
      width: 100%;
    }

    @media @mobile {
      margin: 0 10px 10px 0;
    }
  }

  .profile-name,
  .profile-email,
  .profile-place {
    margin-top: 0;
    margin-bottom: 10px;
    width: 74%;
    float: left;
    word-wrap: break-word;

    span {
      width: 100px;
      display: inline-block;
      font-family: @semibold;

      .lang-no & {
        .nrk-fonts(@style:bolditalic);
      }
    }
  }

  .btn-settings {
    position: absolute;
    bottom: 0;
    right: 0;

    &:before {
      padding-right: 10px;
    }

    @media @mobile {
      position: inherit;
    }
  }
}

.profile-bottom {
  position: relative;
  margin-top: 80px;
  padding-top: 20px;

  &:before {
    position: absolute;
    content: "";
    .gradient(left);
    height: 40px;
    width: 100vw;
    left: calc(~"(100vw - 940px) / 2 * -1");
    z-index: -1;
    top: -40px;

    .lang-no & {
      background-color: @nrk-red;
      background-image: url(../images/nrk/nrk_bg-element.png);
      background-position: center;
      background-size: 120% auto;
      background-repeat: no-repeat;
    }

    @media @content {
      left: -10px;
    }
  }

  h2.title {
    font-size: @font-size-xxl;
  }
}

.profile {
  h2 {
    margin-bottom: 20px;
  }
}

.artist-pictures {
  .form-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    background: @dot-border;

    &.img-upload {
      margin-bottom: 20px;
      padding-bottom: 0;
      background: none;
    }
    textarea {
      min-height: 40px;
    }
  }
}

.img-upload {
  .img-upload-img {
    display: block;
    float: left;
    margin-right: 10px;
    height: 60px;
    width: 60px;

    img {
      width: 100%;
    }
  }
  .artist-pictures & {
    &:nth-child(3) {
      background: @dot-border;
      padding-bottom: 20px;
    }
  }
}

.profile-picture {
  img {
    border-radius: 50%;
  }
}

// Add artist
.add-artistpage {
  position: relative;

  button.btn-settings {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }
}

// Add track
.add-track {
  //display: none;
}

// List of artistpages
ul.list-artistpage {
  > li {
    background-color: @grey-light;
    padding: 20px;
    margin-bottom: 20px;

    @media @mobile {
      padding: 10px;

      .artist-teaser {
        margin-bottom: 20px;
      }
    }

    ul.list-artistpage-tracks {
      margin-bottom: 20px;

      li {
        margin-bottom: 10px;
      }
    }

    h3 {
      color: @grey;
    }

    button {
      margin-bottom: 0;
      margin-top: 0;

      &:before {
        padding-right: 10px;
      }
    }

    .twocol-split {
      margin-bottom: 0;
    }

    .track-teaser {
      background: @dot-border;
      padding-bottom: 10px;
      position: relative;

      button {
        float: right;
        margin-left: 6px;

        &:before {
          padding: 0;
        }
      }
    }

    .artist-teaser,
    .track-teaser {
      .clearfix;
      position: relative;

      .settings {
        position: absolute;
        top: 0px;
        right: 10px;
      }

      .picture {
        width: 40px;
        float: left;
        margin-top: 2px;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .cover {
        width: 40px;
        float: left;

        img {
          width: 100%;
        }
      }

      .artist {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        font-family: @semibold;
        font-size: @font-size-xl;
        line-height: 22px;
        max-width: 300px;
        min-height: 44px;
        overflow: hidden;
        padding-left: 16px;
        margin-right: 16px;

        .lang-no & {
          .nrk-fonts(@style:bolditalic);
        }
      }

      button {
        margin-top: 8px;
      }

      .title {
        font-family: @regular;
        font-size: @font-size-m;
        position: absolute;
        top: calc(~"(50% - 5px)");
        .transform(translateY(-50%));
        width: 100%;
        padding: 0 80px 0 50px;

        // @media @mobile {
        //   float: left;
        //   width: 45%;
        //   position: inherit;
        //   top: 0;
        //   left: 0;
        //   padding-left: 10px;
        //   .transform(translateY(0));
        // }

        .lang-no & {
          .nrk-fonts(@style:bookitalic);
        }
      }
    }
  }
}
